export default {
  computed: {
    listMaxLength: () => 30,
  },

  methods: {
    croppedView(arr, crop = false) {
      if (!Array.isArray(arr)) {
        console.warn('tableView: data should be passed as array')
        return ''
      }

      if (!arr.length) return ''

      const separator = ', '
      const names = arr.map(({ name }) => name)
      const fullList = names.join(separator)
      if (!crop) return fullList
      return fullList.length > this.listMaxLength
        ? fullList.substring(0, this.listMaxLength) + '...'
        : fullList
    },
    stringCroppedView(string, crop = false, length = this.listMaxLength) {
      if (!string) return
      if (!crop) return string
      return string.length > length
        ? string.substring(0, length) + '...'
        : string
    },
  },
}
