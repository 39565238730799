<template>
  <v-btn-toggle
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
    :borderless="borderless"
    :dense="dense"
    light
    :color="color"
    :background-color="backgroundColor"
    :active-class="activeClass"
    @change="$emit('input', $event)"
  >
    <template v-for="(_, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'ui-btn-toggle',
  props: {
    value: null,
    color: {
      type: String,
      default: 'black lighten-2',
    },
    backgroundColor: {
      type: String,
      default: 'black lighten-7',
    },
    borderless: {
      type: Boolean,
      default: true,
    },
    activeClass: {
      type: String,
      default: 'ui-btn-toggle-btn--active',
    },
    dense: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
<style lang="scss">
.ui-btn-toggle-btn--active {
  background-color: var(--v-black-lighten2);
  color: var(--v-black-darken3) !important;
}
</style>
