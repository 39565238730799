import SuperRepository from '../superRepository'

export default new (class countrySettingRepo extends SuperRepository {
  baseUri() {
    return 'country_setting'
  }

  getByCountry = async ({ params, id }) =>
    this.httpClient().get(`${this.baseUri()}/by_country/${id}`, params)
})()
