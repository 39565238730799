<template>
  <v-edit-dialog
    ref="editDialog"
    v-bind="$attrs"
    :menu-props="{ contentClass: 'edit-dialog-control' }"
  >
    <div class="d-flex align-center">
      <v-icon color="black lighten-3" size="16" class="mr-1">mdi-pencil</v-icon>
      <slot />
    </div>
    <template #input>
      <div class="edit-dialog-control">
        <h3 class="dialog-title mb-2 fw-600">
          {{ title || $t('base.change') }}
        </h3>

        <slot name="input" />

        <div class="edit-dialog-control-btns d-flex justify-end pt-2">
          <ui-button theme="outline" size="small" class="mr-1" @click="cancel">
            {{ $t('base.cancel') }}
          </ui-button>

          <ui-button :disabled="loading" :loading="loading" size="small" @click="save">
            {{ $t('base.save') }}
          </ui-button>
        </div>
      </div>
    </template>
  </v-edit-dialog>
</template>

<script>
export default {
  name: 'ui-edit-dialog',
  props: {
    returnValue: null,
    edited: Boolean,
    title: String,
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    save() {
      const el = this.$refs.editDialog
      el.cancel()
      this.$emit('save', el.save)
    },
    cancel() {
      const el = this.$refs.editDialog
      el.cancel()
      this.$emit('cancel', this.returnValue)
    },
  },
}
</script>

<style lang="scss">
.edit-dialog-control {
  .dialog-title {
    font-size: 12px;
  }
}

.v-small-dialog__content {
  padding: 8px;
}
.v-menu__content {
  contain: unset !important;
  overflow-x: unset !important;
  overflow-y: unset !important;
}
</style>
