import StorageService from '@/services/storage.service'
import TableOptions from '@/models/TableOptions'
import {
  hasAppliedFilters,
  omitEmptyDeepFilters,
  omitEmptyFilters,
  prepareFilters,
} from '@/helpers/filters'
import orderRepository from '@/repository/admin/orderRepository'
import orderHistoryRepository from '@/repository/admin/orderHistoryRepository'
import { defaultFilters, prepareOrderReportFilters } from '@/models/Order/helpers'
import storageKeys from '@/constans/storageKeys'
import { DATE_DAY_FORMAT } from '@/constans/date'
const { saveAs } = require('file-saver')
import { formatDate } from '@/helpers/date'
import { hasPermission } from '@/helpers/permission'
import i18n from '@/plugins/vue-i18n'

const state = {
  filters: StorageService.get(storageKeys.ORDER_FILTERS) || { ...defaultFilters },
  orderList: [],
  order: null,
  tableOptions: TableOptions.defaultTableOptions(),
  orderWebList: [],
  selectedColumns: null,
  isEdit: false,
  loadingData: false,
  countrySetting: null,
  isOperator: false,
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  orderList: state => state.orderList,
  order: state => state.order,
  hasAppliedFilters: state => {
    return hasAppliedFilters(state.filters)
  },
  orderWebList: state => state.orderWebList,
  isEdit: state => state.isEdit,
  isOperator: state => state.isOperator,
  canEditOrder: (state, getters) => {
    if (!getters.isEdit) return false
    if (getters.isOperator) {
      return hasPermission(['CC_ORDER_OP_OPERATE'])
    }
    return hasPermission(['CC_ORDER_OPERATE'])
  },
  canCreateOrder: (state, getters) => {
    if (getters.isEdit) return false
    if (getters.isOperator) {
      return hasPermission(['CC_ORDER_OP_CREATE'])
    }
    return hasPermission(['CC_ORDER_CREATE'])
  },
  canImport: (state, getters) => {
    if (getters.isOperator) {
      return hasPermission(['CC_ORDER_OP_IMPORT_FILE'])
    }
    return hasPermission(['CC_ORDER_IMPORT_FILE'])
  },
  canViewPhone: (state, getters) => {
    if (getters.isOperator) {
      return hasPermission(['CC_ORDER_OP_PHONE_VIEW'])
    }
    return hasPermission(['CC_ORDER_PHONE_VIEW'])
  },
  canViewLog: (state, getters) => {
    if (getters.isOperator) {
      return hasPermission(['CC_ORDER_OP_LOG_VIEW'])
    }
    return hasPermission(['CC_ORDER_LOG_VIEW'])
  },
  canInitCall: (state, getters) => {
    if (getters.isOperator) {
      return hasPermission(['CC_ORDER_OP_INIT_CALL'])
    }
    return hasPermission(['CC_ORDER_INIT_CALL'])
  },
  canInitSms: (state, getters) => {
    if (getters.isOperator) {
      return hasPermission(['CC_ORDER_OP_INIT_SMS'])
    }
    return hasPermission(['CC_ORDER_INIT_SMS'])
  },
  canViewReasonStatusAll: (state, getters) => {
    if (getters.isOperator) {
      return hasPermission(['CC_ORDER_OP_STATUS_REASON_ALL'])
    }
    return hasPermission(['CC_ORDER_STATUS_REASON_ALL'])
  },
  canViewReasonStatusOfOperators: (state, getters) => {
    if (getters.isOperator) {
      return hasPermission(['CC_ORDER_OP_STATUS_REASON_OPERATOR'])
    }
    return hasPermission(['CC_ORDER_STATUS_REASON_OPERATOR'])
  },
  canChangeReasonStatusApproved: (state, getters) => {
    if (getters.isOperator) {
      return hasPermission(['CC_ORDER_OP_STATUS_APPROVED'])
    }
    return hasPermission(['CC_ORDER_STATUS_APPROVED'])
  },
  canChangeReasonStatusIncorrect: (state, getters) => {
    if (getters.isOperator) {
      return hasPermission(['CC_ORDER_OP_STATUS_INCORRECT'])
    }
    return hasPermission(['CC_ORDER_STATUS_INCORRECT'])
  },
  canChangeReasonStatusTrash: (state, getters) => {
    if (getters.isOperator) {
      return hasPermission(['CC_ORDER_OP_STATUS_TRASH'])
    }
    return hasPermission(['CC_ORDER_STATUS_TRASH'])
  },
  canChangeReasonStatusCanceled: (state, getters) => {
    if (getters.isOperator) {
      return hasPermission(['CC_ORDER_OP_STATUS_CANCELED'])
    }
    return hasPermission(['CC_ORDER_STATUS_CANCELED'])
  },
  canChangeReasonStatusCanceledRC: (state, getters) => {
    if (getters.isOperator) {
      return hasPermission(['CC_ORDER_OP_STATUS_CANCELED_RC'])
    }
    return hasPermission(['CC_ORDER_STATUS_CANCELED_RC'])
  },
  selectedColumns: state => state.selectedColumns,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
  loadingData: state => state.loadingData,
  isSourceCPA: state => {
    const { partnerId, landing, offer } = state.order || {}
    return !!(partnerId && landing && offer)
  },
  isSourceAddProject: state => {
    const { operator, offer } = state.order || {}
    return !!(operator && offer)
  },
  isSourceIncome: state => {
    // TODO: одинаковое определение статусов с isSourceAddProject, ждет уточнение аналитиков
    const { operator, offer } = state.order || {}
    return !!(operator && offer)
  },
  isSourceUser: state => {
    const { createdBy } = state.order || {}
    return !!createdBy
  },
  countrySetting: state => state.countrySetting,
}

const actions = {
  async loadOrderList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.ORDER_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })
    const { data } = await orderRepository.list(preparedFilters)

    context.commit('setOrderList', { data, filters })
    return { orderList: data.items, pagination: data.pagination }
  },
  async searchOrderList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await orderRepository.list(preparedFilters)

    return { orderList: data.items, pagination: data.pagination }
  },
  async loadOrder(context, id) {
    context.commit('setLoadingData', true)
    const { data } = await orderRepository.get(id)
    context.commit('setOrder', data)
    context.commit('setLoadingData', false)

    return data
  },
  async loadAdditionalPhone(context, id) {
    const { data } = await orderRepository.loadAdditionalPhone(id)
    return data
  },
  async loadQuestionnaireByOrder(context, id) {
    const { data } = await orderRepository.loadOrderQuestionnaire(id)
    return data
  },
  async loadOrderHistory(context, payload) {
    const { id, params } = payload
    const appliedFilters = omitEmptyDeepFilters(params.filter)
    const appliedCfFilters = omitEmptyDeepFilters(params.cfFilter)

    const preparedFilters = prepareFilters({
      ...params,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await orderRepository.loadOrderHistory({ id: id, params: preparedFilters })

    return { orderHistoryList: data.items || [], pagination: data.pagination }
  },
  async loadLogHistory(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await orderHistoryRepository.list(preparedFilters)

    return { orderHistoryList: data.items || [], pagination: data.pagination }
  },
  async orderReport(context, payload) {
    const appliedFilters = omitEmptyFilters(payload)

    const response = await orderRepository.report(appliedFilters, { responseType: 'blob' })
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `заявки_${formatDate(appliedFilters.dateFrom, DATE_DAY_FORMAT)}-${formatDate(
      payload.dateTo,
      DATE_DAY_FORMAT,
    )}.xlsx`
    saveAs(blob, filename)
    return response
  },
  async createPackerTaskPDF(context, payload) {
    const response = await orderRepository.createPackerTask(
      {
        ...payload,
        xlsx: false,
      },
      { responseType: 'blob' },
    )
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.tc('order.packerTask')} ${formatDate(new Date(), DATE_DAY_FORMAT)}.pdf`
    saveAs(blob, filename)
    return response
  },
  async createPackerTaskXLSX(context, payload) {
    const response = await orderRepository.createPackerTask(
      {
        ...payload,
        xlsx: true,
      },
      { responseType: 'blob' },
    )

    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.tc('order.packerTask')} ${formatDate(
      new Date(),
      DATE_DAY_FORMAT,
    )}.xlsx`
    saveAs(blob, filename)
    return response
  },
  createOrder(context, payload) {
    return orderRepository.post(payload)
  },
  bulkUpdateOrder(context, payload) {
    return orderRepository.bulkUpdate(payload)
  },
  updateOrder(context, payload) {
    const { id, ...data } = payload
    context.commit('setLoadingData', true)
    const { result } = orderRepository.put(id, data)
    context.commit('setLoadingData', false)
    return result
  },
  deleteOrder(context, payload) {
    return orderRepository.delete(payload)
  },

  setSelectedColumns({ commit }, payload) {
    const filters = StorageService.get(storageKeys.ORDER_FILTERS)
    filters.clientOptions.selectedColumns = payload
    StorageService.set(storageKeys.ORDER_FILTERS, filters)

    commit('setSelectedColumns', payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.ORDER_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.ORDER_FILTERS, updatedFilters)
  },
  async ordersWithoutBarcode(context, payload) {
    const appliedFilters = prepareOrderReportFilters(payload)
    const response = await orderRepository.ordersWithoutBarcodeReport(appliedFilters, {
      responseType: 'blob',
    })
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.tc('reportWithoutBarcode.downloadFileName')}.xlsx`
    saveAs(blob, filename)
    return response
  },
}

const mutations = {
  setStateEditOrder(state, payload) {
    state.isEdit = payload
  },
  setOrder(state, payload) {
    state.order = payload
  },
  setOrderList(state, { data, filters }) {
    state.orderList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
  setOrderWebList(state, payload) {
    state.orderWebList = payload
  },
  setSelectedColumns(state, payload) {
    state.selectedColumns = payload
  },
  setLoadingData(state, payload) {
    state.loadingData = payload
  },
  setCountrySettingOrder(state, payload) {
    state.countrySetting = payload
  },
  setOperator(state, payload) {
    state.isOperator = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
