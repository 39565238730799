import SuperRepository from '../superRepository'

export default new class ProfileRepository extends SuperRepository {
  baseUri () {
    return 'profile'
  }
  get = async () => this.httpClient().get(`${this.baseUri()}`)
  recover = async (data, params) => this.httpClient().post(`${this.baseUri()}/recover_password_mail`, data, params)
  resetPassword = async (data, params) => this.httpClient().post(`${this.baseUri()}/reset_password?token=${params.token}`, data)
}
