<template>
  <v-list-item
      class="ui-list-item px-0"
      :class="isSelected && 'ui-list-item--active'"
  >

    <v-list-item-avatar
        v-if="$scopedSlots.avatar"
        max-width="20"
        min-width="20"
        max-height="20"
        class="ui-list-item-avatar"
    >
      <slot name="avatar"/>
    </v-list-item-avatar>

    <v-list-item-content
        class="ui-list-item-content py-0 cursor-pointer"
        @click="$emit('onSelect')"
    >
      <v-list-item-title
          class="ui-list-item-title"
          :class="isSelected && 'ui-list-item-title--active'"
      >
        <slot/>
      </v-list-item-title>
    </v-list-item-content>

    <v-list-item-action
        v-if="$scopedSlots.action"
        max-width="20"
        min-width="20"
        max-height="20"
        class="ui-list-item-action"
    >
      <slot name="action"/>
    </v-list-item-action>

  </v-list-item>
</template>

<script>
export default {
  name: "ui-list-item",
  props: {
    isSelected: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-list-item {
  min-height: 32px;
  min-width: 100px;

  &-avatar {
    margin: 0;
    justify-content: start;
  }

  &--active {
    background-color: var(--v-primary-base) !important;
  }

  &:hover {
    background-color: var(--v-primary-lighten6);
  }

  &-title {
    color: var(--v-black-darken3);
    font-weight: 400;
    font-size: 12px !important;
    line-height: 20px;

    &--active {
      color: white !important;
    }
  }

  &-action {
    margin: 0;
  }
}
</style>
