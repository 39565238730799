import SuperRepository from '../superRepository'

export default new (class AuthRepository extends SuperRepository {
  baseUri() {
    return 'token'
  }
  login = async data => this.httpClient().post(this.baseUri(), data)
  logout = async data => this.httpClient().post(`${this.baseUri()}/logout`, data)
  refreshToken = async data => this.httpClient().post(`${this.baseUri()}/refresh`, data)
})()
