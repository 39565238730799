import SuperRepository from '../superRepository'

export default new (class deliveryPointRepo extends SuperRepository {
  baseUri() {
    return 'delivery_point'
  }

  listDeliveryIntervals = async ({ params, id }) =>
    this.httpClient().get(`${this.baseUri()}/${id}/delivery_intervals`, params)

  bulkUpdate = async (data, params) =>
    this.httpClient().put(`${this.baseUri()}/bulk_update`, data, params)
})()
