<template>
  <v-btn depressed
         v-bind="$attrs"
         v-on="$listeners"
         class="ui-btn"
  >
    <template v-for="(_, name) in $slots" v-slot:[name]>
      <slot :name="name"/>
    </template>
    <v-icon v-if="editIcon" :color="iconColor" :size="iconSize">mdi-pencil-outline</v-icon>
    <v-icon v-else-if="removeIcon" :color="iconColor" :size="iconSize">mdi-delete-outline</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "ui-btn",
  props: {
    editIcon: {
      type: Boolean,
      default: false
    },
    removeIcon: {
      type: Boolean,
      default: false
    },
    iconColor: {
      type: String,
      default: 'info'
    },
    iconSize: {
      type: String,
      default: undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-btn {
  font-size: 12px !important;
  font-weight: 700;
  line-height: 20px !important;
  letter-spacing: 0em;

  &.v-size--x-small {
    color: #5F6163;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 20px;
  }

  &.v-btn--outlined.info--text {
    border-color: var(--v-black-lighten6);
  }
}

.btn-info—border-light.v-btn--outlined {
  border: 1px var(--v-black-lighten6) solid !important;
  color: #5F6163 !important;
}

.ui-btn:not(.v-btn--outlined).lightPrimary {
  color: #898989;
}

.ui-btn:not(.v-btn--outlined).lightPrimary.v-btn--active {
  color: var(--v-primary-base);
}
</style>
