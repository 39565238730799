export default [
  {
    path: '/settings/product',
    name: 'pageProductList',
    meta: { title: 'page.pageProductList', roles: ['SETTING_PRODUCT_VIEW'] },
    component: () => import('@/pages/settings/product'),
  },
  {
    path: '/settings/product/create',
    name: 'pageProductCreate',
    meta: { title: 'page.pageProductCreate', roles: ['SETTING_PRODUCT_VIEW'] },
    component: () => import('@/pages/settings/product/create'),
  },
  {
    path: '/settings/product/:id/edit',
    name: 'pageProductEdit',
    meta: { title: 'page.pageProductEdit', roles: ['SETTING_PRODUCT_VIEW'] },
    component: () => import('@/pages/settings/product/id/edit'),
  },
]
