import { cloneDeep } from 'lodash'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    offers: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    countries: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    language: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    salesType: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    salesModel: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    active: 1,
  },
  sortBy: ['active', 'id'],
  sortDesc: [true, true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export function preparedSalesTypeFilters(salesTypeFilters, { salesType, salesModel }) {
  const preparedSalesType = cloneDeep(salesTypeFilters)

  preparedSalesType.id = salesType?.id
  preparedSalesType.salesModels.id = salesModel?.id

  return preparedSalesType
}
