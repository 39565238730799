export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    dateFrom: '2023-02-20',
    dateTo: '2023-02-27',
    groupByWeb: false,
    offers: [],
    groups: [],
    web: [],
  },
  search: '',
  clientOptions: {
    filterPanelExpanded: false,
  },
}
