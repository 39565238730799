<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="12">
        <div class="d-flex align-center justify-space-between">
          <div class="black--text text--darken-6 text-h1 py-5 custom-title">
            <span
              class="cursor-pointer"
              v-tooltip="{
                content: title,
                placement: 'top-end',
              }"
            >
              {{ title }}</span
            >
          </div>
          <div v-if="isShowActions" class="d-flex justify-end">
            <slot name="actions">
              <slot name="leftActions" />

              <ui-button v-if="!hideFilter" variant="white" class="mx-3" @click="onToggleFilter">
                <template #iconLeft>
                  <v-icon color="black darken-3">mdi-filter-variant</v-icon>
                </template>
                {{ $t('base.filters') }}
              </ui-button>

              <slot name="defaultCreate">
                <ui-button
                  v-if="!hideDefaultCreate"
                  v-permission="btnCreate.permission"
                  @click="$router.push({ name: btnCreate.path })"
                >
                  <template #iconLeft>
                    <v-icon>{{ btnCreate.icon || 'mdi-plus' }}</v-icon>
                  </template>
                  {{ btnCreate.title }}
                </ui-button>
              </slot>

              <slot name="rightActions" />
            </slot>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col>
        <slot />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ui-list-entity',
  props: {
    title: {
      type: String,
      default: '',
    },
    isShowActions: {
      type: Boolean,
      default: true,
    },
    hideFilter: {
      type: Boolean,
      default: false,
    },
    hideDefaultCreate: {
      type: Boolean,
      default: false,
    },
    btnCreate: {
      type: Object,
      default: () => {
        return {
          path: '',
          title: '',
          icon: false,
          permission: [],
        }
      },
    },
  },
  methods: {
    onToggleFilter() {
      this.$emit('toggleFilter')
    },
  },
}
</script>

<style lang="scss">
.custom-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
