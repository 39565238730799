import SuperRepository from '../superRepository'

export default new (class EventsRepository extends SuperRepository {
  baseUri() {
    return 'event'
  }
  exportUserEvents = ({id, ...data}, params = {}) =>
    this.httpClient().getFile(`${this.baseUri()}/${id}/export_user_events`, data, params)
  exportEventUsers = (id, params = {}) =>
    this.httpClient().getFile(`${this.baseUri()}/${id}/export_users`, params)
})()
