import SuperRepository from '../superRepository'

export default new (class klnSectionRepository extends SuperRepository {
  baseUri() {
    return 'kln_section'
  }
  getItems = async () => this.httpClient().get(`${this.baseUri()}/get_items`)
  statisticsReport = async params =>
    this.httpClient().post(`${this.baseUri()}/statistics_report`, params)
})()
