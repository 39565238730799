export const locale = {
  // __GENERATED_TRANSLATIONS__ 
  userReport: {
     listTitle: 'Звіти',
     editFormTitle: 'Перегляд заходу',
     createFormTitle: 'Створення заходу',
     formFieldsBlockTitle: 'Основна інформація',
     formCreateUpdateSubmitErrorMessage: 'Помилка під час запиту',
     menuItemName: 'Звіти',
     table: {
       id: '№',
       name: 'Назва заходу',
       point: 'Бал',
       actions: 'Дії',
     },
     field: {
       name: 'Назва заходу*',
       type: 'Тип заходу*',
       date: 'Дата та час проведення*',
       link: 'Посилання на захід',
       role: 'Роль виконавця на заході*',
       description: 'Опис заходу',
     },
  },
   
  reports: {
     listTitle: 'Звіти',
     editFormTitle: 'Перегляд заходу',
     createFormTitle: 'Створення заходу',
     formFieldsBlockTitle: 'Основна інформація',
     formCreateUpdateSubmitErrorMessage: 'Помилка під час запиту',
     menuItemName: 'Звіти',
     table: {
       id: '№',
       name: 'Виконавець',
       actions: 'Дії',
       total: 'Загалом',
     },
     field: {
       name: 'Назва заходу*',
       type: 'Тип заходу*',
       date: 'Дата та час проведення*',
       link: 'Посилання на захід',
       role: 'Роль виконавця на заході*',
       description: 'Опис заходу',
     },
  },
   
  systemEvents: {
    listTitle: 'Заходи',
    editFormTitle: 'Перегляд заходу',
    createFormTitle: 'Створення заходу',
    formFieldsBlockTitle: 'Основна інформація',
    formCreateUpdateSubmitErrorMessage: 'Помилка під час запиту',
    addParticipants: 'Додати учасників',
    menuItemName: 'Заходи',
    adminVideo: 'Відео від адміністрації',
    otherFiles: 'Файли та фото',
    addToCalendar: 'Додати захід до календаря',
    eventAdded: 'Захід додано!',
    eventAddedDesc: 'Захід успішно додано до календаря',
    adminVideoDesc: 'Бали нараховуються за перегляд відео постфактум. За присутність на заході передбачений більший бал.',
    table: {
      id: '№',
      name: 'Назва заходу',
      type: 'Тип заходу',
      date: 'Дата та час',
      speakers: 'Список доповідачів',
      description: 'Опис заходу',
      actions: 'Дії',
    },
    userTable: {
      id: '№',
      name: 'Iм\'я та прізвище',
      email: 'Пошта',
      presence: 'Присутність',
      point: 'Бал',
      comment: 'Коментар',
      actions: 'Дії',
    },
    userTableTypes: {
      participation: 'Брав участь',
      reporter: 'Доповідав',
      watch_video: 'Переглянув відео'
    },
    field: {
      name: 'Назва заходу*',
      type: 'Тип заходу*',
      date: 'Дата та час проведення*',
      link: 'Посилання на захід',
      role: 'Роль виконавця на заході*',
      roleShow: 'Роль виконавця на заході',
      speakers: 'Список доповідачів*',
      speakersShow: 'Список доповідачів',
      description: 'Опис заходу',
    },
  },

  events: {
    listTitle: 'Мої заходи',
    editFormTitle: 'Перегляд заходу',
    createFormTitle: 'Створення заходу',
    formFieldsBlockTitle: 'Основна інформація',
    formFieldsSecondBlockTitle: 'Учасники заходу',
    formCreateUpdateSubmitErrorMessage: 'Помилка під час запиту',
    menuItemName: 'Мої заходи',
    export: 'Учасники',
    table: {
      id: '№',
      name: 'Назва заходу',
      type: 'Тип заходу',
      date: 'Дата та час',
      role: 'Роль виконавця на заході',
      description: 'Опис заходу',
      point: 'Бал',
      actions: 'Дії',
    },
    field: {
      name: 'Назва заходу*',
      nameShow: 'Назва заходу',
      type: 'Тип заходу*',
      typeShow: 'Тип заходу',
      date: 'Дата та час проведення*',
      dateShow: 'Дата та час проведення',
      link: 'Посилання на захід',
      role: 'Роль виконавця на заході*',
      roleShow: 'Роль виконавця на заході',
      description: 'Опис заходу*',
      descriptionShow: 'Опис заходу',
      comment: 'Коментар',
      point: 'Ваш бал за участь у заході',
    },
  },

  users: {
    listTitle: 'Користувачі',
    editFormTitle: 'Перегляд користувача',
    createFormTitle: 'Створення користувача',
    formFieldsBlockTitle: 'Основна інформація',
    formFieldsSecondBlockTitle: 'Заходи',
    formCreateUpdateSubmitErrorMessage: 'Помилка під час запиту',
    menuItemName: 'Користувачі',
    export: 'Експортувати звіт',
    report: 'Звіт',
    exportFile: 'Заходи',
    executorNotifications: ' нових заходів',
    table: {
      id: '№',
      name: 'Iм\'я та Прізвище',
      role: 'Роль',
      email: 'Пошта',
      notifications: 'Сповіщення',
      actions: 'Дії',
    },
    eventsTable: {
      id: '№',
      name: 'Назва заходу',
      type: 'Тип заходу',
      source: 'Джерело заходу',
      date: 'Дата та час',
      role: 'Роль виконавця',
      description: 'Опис заходу',
      point: 'Бал',
      comment: 'Коментар',
      leftComment: 'Залиште коментар',
      actions: 'Дії',
    },
    field: {
      name: 'Iм\'я*',
      lastName: 'Прізвище*',
      role: 'Роль*',
      roleShow: 'Роль',
      email: 'Пошта*',
      password: 'Пароль*',
      passNotReq: 'Пароль',
    },
    roles: {
      executor: 'Виконавець',
      admin: 'Адміністратор'
    },
    types: {
      reporter: 'Доповідач',
      participation: 'Учасник',
    }
  },
  settings: {
    settingsTitle: 'Налаштування',
    settingsGeneral: 'Залікові бали',
    settingsExecutor: 'Основна інформація',
    menuItemName: 'Settings',
    saveSuccess: 'Data successfully updated',
    field: {
      storyMark: 'Бал за доповідь*',
      partMark: 'Бал за участь*',
      recordMark: 'Бал за перегляд відеолекції (постфактум)*',
      name: 'Iм\'я*',
      lastName: 'Прізвище*'
    },
    btn: {
      save: 'Save',
    },
  },
  base: {
    mainPage: 'Головна',
    goToMain: 'На головну',
    settings: 'Налаштування',
    activate: 'Активувати',
    deactivate: 'Деактивувати',
    add: 'Створити',
    reset: 'Скинути',
    apply: 'Підтвердити',
    decline: 'Відмінити',
    save: 'Зберегти',
    selectAll: 'Обрати все',
    clearAll: 'Очистити все',
    areYouSureToDelete: 'Вы дійсно бажаєте видалити?',
    areYouSureToDeactivate: 'Підтвердіть деактивацію',
    areYouSureToActivate: 'Підтвердіть активацію',
    back: 'Назад',
    cancel: 'Скасувати',
    failedToLoad: 'Неможливо завантажити',
    failedToLoadDescription: 'Помилка під час завантаження сторінки. Спробуйте перезавантажити сторінку',
    filters: 'Фільтри',
    noData: 'Немає даних',
    onPage: 'Показано',
    resetFilters: 'Скинути',
    search: 'Пошук',
    warning: 'Увага',
    beWaiting: 'Зачекайте',
    dataSaving: 'Триває обробка даних',
    selectDate: 'Обрати дату',
    choosePeriod: 'Обрати період',
    selectPeriod: 'Обрати період',
    presets: {
      today: 'Сьогодні',
      todayAndYesterday: 'Сьогодні та вчора',
      yesterday: 'Вчора',
      currentWeek: 'Поточний тиждень',
      previousWeek: 'Минулий тиждень',
      currentMonth: 'Поточний місяць',
      previousMonth: 'Минулий місяць',
      currentYear: 'Поточний рік',
    },
    success: 'Успішно',
    saveSuccess: 'Дані успішно збережені',
    limitText: 'та ще {count}',
    validationError: 'Помилка',
    validationErrorServer: 'Помилка валідації на сервері',
    mobileView: 'Для коректного відображення використовуйте десктопну версію',
    noMoreItems: 'Весь список завантажений',
  },
  filters: {
    activity: {
      name: 'Статус',
      all: 'Усі',
      active: 'Активний',
      deactivated: 'Деактивований',
    },
    events: {
      name: 'Тип заходу',
      conference: 'Конференція',
      seminar: 'Семінар',
      webinar: 'Вебінар',
      working_group: 'Діяльність робочої групи',
      other: 'Інша діяльність',
      international_conference: 'Конференція міжнародна',
      all_ukrainian_conference: 'Конференція всеукраїнська',
      regional_conference: 'Конференція регіональна',
      round_table: 'Круглий стіл',
      training: 'Тренінг',
      development_of_npa: 'Розробка НПА АПВУ',
      training_course: 'Навчальні курси',
      teaching_activity: 'Викладацька діяльність',
      article_textbook: 'Стаття/монографія/підручник',
      obtain_scientific_degree: 'Здобуття наукового ступеня',
    },
    source: {
      name: 'Джерело заходу',
      system: 'Системні',
      personal: 'Особисті',
      all: 'Усі',
    },
    presence: {
      name: 'Присутність',
      present: 'Присутній',
      absent: 'Відсутній'
    }
  },
  lang: {
    ru: 'Російська',
    ua: 'Українська',
    en: 'Англійська'
  },
  page: {
    pageError403: 'Доступ заборонено',
    pageError404: 'Сторінка не знайдена',
  },
  authLogin: {
    title: 'Авторизація',
    username: 'Електронна пошта',
    password: 'Пароль',
    loginIn: 'Увійти',
    invalidCredentials: 'Невірний email або пароль!',
    userDeactivated: 'Ваш акаунт було деактивовано. Зверніться до адміністрації.',
    forgetPassword: 'Забули пароль?',
    welcome: 'Ласкаво просимо!',
    authText: 'Підвищення кваліфікації є запорукою',
    subText1: 'Високого професіоналізму виконавця',
    subText2: 'Ефективного примусового виконання рішень',
    subText3: 'Успішності інституту приватного виконання',
  },
  authRestore: {
    title: 'Забули пароль?',
    email: 'Електронна пошта',
    forgetPassword: 'Забули пароль?',
    authRestoreDesc: 'Введіть свою електронну адресу та отримайте посилання для відновлення:',
    send: 'Надіслати',
    checkYourEmail: 'Перевірте пошту',
    sentDesc: 'Ми надіслали посилання для зміни пароля',
    wrongEmail: 'Неправильна електронна адреса?',
    loginAgain: 'Увійдіть знову!',
    isNotFounded: 'Email не відповідає жодному обліковому запису. Спробуйте ще раз!',
  },
  authRecoveryPassword: {
    title: 'Відновлення паролю',
    createNewPassword: 'Створіть пароль',
    create: 'Створити',
    first: 'Новий пароль',
    second: 'Повторіть',
    titleOfDemands: 'Ваш пароль повинен складатися з:',
    lengthDemand: 'мінімум 8 символів',
    capitalDemand: 'одну заголовну букву',
    lowercaseDemand: 'одну малу літеру',
    numberDemand: 'одну цифру (0-9)',
    symbolDemand: 'один символ (!@#%&*.)',
    example: 'Приклад',
    exampleSymbols: 'Vtlq3*fsdfhqwj*',
    length: '8',
    generatePassword: 'Згенерувати пароль',
    sameField: 'Паролі повинні збігатися',
  },
  error: {
    invalid: '{name} не є допустимим',
    required: '{name} обов\'язкове поле',
    requiredIf: '{name} обов\'язкове поле',
    email: 'Невірний формат Email',
    maxLength: 'Максимальна довжина символів {length}',
    minLength: 'Мінімальна довжина символів {length}',
    maxValue: 'Максимальне значення: {value}',
    minValue: 'Мінімальне значення: {value}',
    onlyNumbers: 'Вводити допустимо лише цифри',
    onlyIntegerNumbers: 'Вводити допустимо лише цілі числа',
    onlyWords: 'Вводити допустимо лише літери',
    allow: 'Ваш пароль повинен складатися з: ',
    sameField: 'Паролі повинні збігатися',
    regexPassword: 'Неправильний формат пароля',
    url: 'Невірний формат URL',
    dateFromLess: 'Дата "з" повинна бути меншою ніж дата "по"',
    dateToLess: 'Дата "по" має бути більшою за дату "з"',
    commaSpaceRule: 'Початок і кінець поля не повинні містити пробіл або кому',
    lengthDemand: 'мінімум 8 символів',
    capitalDemand: 'одну заголовну букву',
    lowercaseDemand: 'одну малу літеру',
    numberDemand: 'одну цифру (0-9)',
    symbolDemand: 'один символ (!@#%&*.)',
    latinDemand: 'Пароль містить лише латинські літери',
    invalidValue: 'Неправильне значення',
    notBeEmpty: 'Значення не повинно бути порожнім',
    error403Title: 'Ой, сталася помилка...',
    error403Desc: 'Сторінка не існує або у вас немає прав доступу до неї.',
    error404Title: 'Ой, сталася помилка...',
    error404Desc: 'Сторінка не існує або у вас немає прав доступу до неї.',
    emailExist: 'Така пошта вже є у системі',
  },
  welcomePage: {
    singleSolution: 'Єдине рішення для:',
    callCentre: 'Кол-центру',
    logistics: 'Логістики',
    management: 'Менеджменту',
  },
  dashboardNotifications: {
    notifications: 'Повідомлення',
    clear: 'Очистити',
    unread: 'Непрочитані',
    read: 'Прочитані',
    listIsEmpty: 'Список повідомлень порожній',
    loading: 'Завантаження повідомлень',
  },
  notifications: {
    new_event: 'Новий захід',
    new_point: 'Нова оцінка',
    new_comment: 'Новий коментар',
  },
  uploadArea: {
    maxSize: 'Максимальный розмір файлу {maxSize} MB',
    maxLimit: 'Ви можете завантажити до {limit} файлів (PDF, DOCX, XLS, XLSX, PPTX, TXT) ' +
        'та фото (JPEG, JPG, PNG, GIF), максимальний розмір до {maxSize} мб.',
    maxVideoLimit: 'Ви можете завантажити {limit} відео (МР4, MOV), максимальный розмір до {maxSize} мб.',
    maxMultipleLimit: 'Ви можете завантажити до {limit} файлів (PDF, DOCX, XLS, XLSX, PPTX, TXT) ' +
      'та фото (JPEG, JPG, PNG, GIF), максимальний розмір до {maxSize} мб та {videoLimit} відео (MP4, MOV), ' +
      'максимальний розмір до {videoMaxSize} мб',
    moveYourFileOr: 'Перетягніть файл/фото або',
    moveYourVideoOr: 'Перетягніть відео або',
    moveYourMultipleOr: 'Перетягніть файл, фото, відео або',
    upload: 'завантажте',
    limit: 'Максимальна кількість файлів, які можна завантажити - {limit}',
    invalidType: 'Невірний формат файлу для завантаження',
  },
    modalSuccessError: {
      close: 'Закрити',
      success: 'Успіх!',
      error: 'Помилка',
      successSync: 'Успішно синхронізовано',
      errorSync: 'Помилка під час синхронізації',
    },
}

export default locale
