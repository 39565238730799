<script>

export default {
  name: 'MediaFormatsMixin',
  computed: {
    imageFormat(){
      return [
        'jpg', 'JPG',
        'jpeg', 'JPEG',
        'png', 'PNG',
        'gif', 'GIF',
      ]
    },
    videoFormat(){
      return [
        'MP4', 'mp4',
        'MOV', 'mov',
      ]
    },
    fileFormat(){
      return [
        'PDF', 'pdf',
        'DOCX', 'docx',
        'XLS', 'xls',
        'XLSX', 'xlsx',
        'PPTX', 'pptx',
        'TXT', 'txt',
      ]
    },
    supportedFormat(){
      return [...this.imageFormat, ...this.fileFormat]
    },
    availableFormats(){
      return [...this.imageFormat, ...this.videoFormat, ...this.fileFormat]
    }
  }
}

</script>