import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/UserEvents/helpers'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import EventPointRepository from "@/repository/generatedRepository/eventPointRepository";

const state = {
  list: [],
  filters: StorageService.get(storageKeys.USER_EVENTS_FILTERS) || { ...defaultFilters },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  list: state => state.list,
}

const actions = {
  async loadUserEvents(context, payload) {
    const {id, filters} = payload
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.USER_EVENTS_FILTERS, filters)
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: {
        ...appliedFilters,
        user: {
          id: id
        }
      },
      cfFilter: appliedCfFilters,
    })
    const { data } = await EventPointRepository.list(preparedFilters, {headers: {'additional-groups': 'point_event'}})
    context.commit('setList', { data, filters })
    return { products: data.items, pagination: data.pagination }
  },
  async updateUserEvent(context, payload){
    const { id, ...data } = payload
    return EventPointRepository.put(id, data)
  },
  filtersStorageReset(context) {
    StorageService.remove(storageKeys.USER_EVENTS_FILTERS)
    context.commit('setFilters', {...defaultFilters})
  },
}

const mutations = {
  setList(state, { data, filters }) {
    state.list = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}