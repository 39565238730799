import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/WorkTime/helpers'
import TableOptions from '@/models/TableOptions'
import { mockPagination, omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import workTimeRepo from '@/repository/admin/workTimeRepository'

const state = {
  filters: StorageService.get(storageKeys.WORK_TIME_FILTERS) || { ...defaultFilters },
  workTimeList: [],
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  workTimeList: state => state.workTimeList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadWorkTimeList(context, filters) {
    context.commit('setFilters', filters)

    StorageService.set(storageKeys.WORK_TIME_FILTERS, filters)

    let groupsFilter = undefined

    if (filters.filter.userGroups && filters.filter.userGroups.id.value) {
      const groups = [filters.filter.userGroups.id.value.replace(/[|]+/g, ',')]
      groupsFilter = {
        groups: groups,
      }
    }
    const preparedFilters = prepareFilters({
      ...groupsFilter,
      dateFrom: filters.filter.dateFrom,
      dateTo: filters.filter.dateTo,
    })

    const { data } = await workTimeRepo.report(preparedFilters)

    context.commit('setWorkTime', {
      data,
      pagination: mockPagination(data, filters),
      filters,
    })

    return { workTimeList: data }
  },
  async searchWorkTimeList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await workTimeRepo.list(preparedFilters)

    return { workTimeList: data }
  },
  updateStorageFilters(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.WORK_TIME_FILTERS, filters)
  },
  async loadWorkTime(context, id) {
    const { data } = await workTimeRepo.get(id)
    return data
  },
  async personalBreak() {
    const { data } = await workTimeRepo.break()
    return data
  },
  createWorkTime(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.WORK_TIME_FILTERS, filters)
    const payload = {
      type: filters.filter.status,
    }
    return workTimeRepo.post(payload)
  },
  updateWorkTime(context, payload) {
    const { id, ...data } = payload
    return workTimeRepo.put(id, data)
  },
  deleteWorkTime(context, payload) {
    return workTimeRepo.delete(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.WORK_TIME_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.WORK_TIME_FILTERS, updatedFilters)
  },
}

const mutations = {
  setWorkTime(state, { data, filters }) {
    state.workTimeList = [...data]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
