export default [
  {
    path: '/manager/efficiency-of-additional-projects',
    name: 'EfficiencyOfAdditionalProjects',
    meta: {
      title: 'page.pageEfficiencyOfAdditionalProjects',
      roles: ['MNG_EFFICIENCY_ADD_PROJECT'],
    },
    component: () => import('@/pages/manager/efficiency-additional-projects'),
  },
]
