<template>
  <autocomplete
    ref="autocomplete"
    :search="search"
    :default-value="value"
    :get-result-value="getResultValue"
    :debounce-time="debounceTime"
    @submit="$emit('submit', $event)"
  >
    <template
      #default="{
        rootProps,
        inputProps,
        inputListeners,
        resultListProps,
        resultListListeners,
        results,
        resultProps,
      }"
    >
      <div v-bind="rootProps">
        <ui-text-field
          ref="input"
          v-bind="inputProps"
          :label="label"
          :error-messages="errorMessages"
          @blur="inputListeners.blur"
          @focus="inputListeners.focus"
          @keydown="inputListeners.keydown"
          @input="onInput($event, inputListeners)"
        />
        <ul v-bind="resultListProps" v-on="resultListListeners">
          <li v-for="(result, index) in results" :key="index" v-bind="resultProps[index]">
            <slot name="option" :option="result">
              {{ result?.[returnProp] }}
            </slot>
          </li>
        </ul>
      </div>
    </template>
  </autocomplete>
</template>

<script>
export default {
  name: 'ui-autocomplete-field',
  props: {
    value: null,
    search: {
      type: Function,
      default: () => [],
    },
    debounceTime: {
      type: Number,
      default: 300,
    },
    errorMessages: {
      type: [String, Array, Object],
    },
    label: {
      type: String,
      default: '',
    },
    returnProp: {
      type: String,
      default: 'value',
    },
  },
  watch: {
    value(v) {
      this.$refs.autocomplete.value = v || ''
    },
  },
  methods: {
    getResultValue(result) {
      return result[this.returnProp]
    },
    onInput(value, inputListeners) {
      const event = {
        target: {
          value,
        },
      }
      inputListeners.input(event)
      this.$emit('input', value)
    },
  },
}
</script>
<style lang="scss">
.autocomplete-result-list {
  display: block;
  z-index: 90 !important;
  width: auto;
  min-width: 100%;
  padding: 8px 0 !important;
  box-shadow: 0px 9px 46px 8px rgba(188, 188, 188, 0.12),
    0px 24px 38px 3px rgba(188, 188, 188, 0.14), 0px 11px 15px -7px rgba(188, 188, 188, 0.2);
  background: #fff;
  max-height: 240px;
  overflow: auto;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  -webkit-overflow-scrolling: touch;
  list-style-type: none;
  margin: 0;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--v-black-darken3);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: var(--v-black-lighten6);
  }

  scrollbar-color: var(--v-black-darken3) var(--v-black-lighten6);
  scrollbar-width: thin;

  .autocomplete-result,
  .autocomplete-result::after {
    background: white !important;
    color: var(--v-black-darken3);
    min-height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 12px;
  }

  .autocomplete-result:hover {
    background: var(--v-primary-lighten6) !important;
    color: var(--v-black-darken3);
  }
}
</style>
