import SuperRepository from '../superRepository'

export default new class ProductRepository extends SuperRepository {
  baseUri () {
    return 'product'
  }

  getOneC = async (id, params = {}) => this.httpClient().get(`product_one_c/${id}`, params)

}
