import SuperRepository from '../superRepository'

export default new (class ReportsRepository extends SuperRepository {
  baseUri() {
    return 'event_report'
  }
  getReport = params =>
    this.httpClient().get(`${this.baseUri()}/main_report`, params)
  exportReport = params =>
    this.httpClient().getFile(`${this.baseUri()}/main_report`, params)
})()
