export default [
  {
    path: '/auth',
    component: () => import('@/pages/auth/Auth'),
    children: [
      {
        name: 'loginPage',
        path: '/auth/login',
        meta: { title: 'authLogin.title' },
        component: () => import('@/pages/auth/Login'),
      },
      {
        name: 'restorePage',
        path: '/auth/restore',
        meta: { title: 'authRestore.title' },
        component: () => import('@/pages/auth/Restore'),
      },
      {
        name: 'recoveryPassword',
        path: '/auth/recovery-password/:token',
        meta: { title: 'authRecoveryPassword.title' },
        component: () => import('@/pages/auth/RecoveryPassword'),
      },
    ],
  },
]
