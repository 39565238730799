export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    groups: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    userGroups: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    products: {
      type: {
        value: '',
        operator: 'in',
      },
      products: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    niche: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    grades: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    salesType: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    salesModel: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    active: 1,
  },
  sortBy: ['active', 'id'],
  sortDesc: [true, true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}
