import Vue from 'vue'
import store from '@/store'
import VueSocketIO from 'vue-socket.io'

// Todo move to .env file
// ws.crm-dev.dev-team.space
// ws.crm-test.dev-team.space

export default function (token) {
  Vue.use(
    new VueSocketIO({
      debug: false,
      // Todo: move socket server url to .env file
      connection: 'https://ws.crm-dev.dev-team.space',
      vuex: {
        store,
        actionPrefix: 'ws_',
      },
      options: {
        autoConnect: true,
        query: {
          token: token,
        },
      },
    }),
  )
}
