<template>
  <v-container fluid class="sticky-header" :style="getStyles">
    <slot/>
  </v-container>
</template>

<script>
export default {
  name: "StickyHeader",
  props: {
    top: {
      type: [Number, String],
      default: '56'
    },
    backgroundColor: {
      type: String,
      default: ''
    }
  },
  computed: {
    getStyles() {
      return {
        top: this.top + 'px',
        backgroundColor: this.backgroundColor
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sticky-header {
  /*position: sticky;*/
  z-index: 10;
}
</style>
