import Vue from 'vue'

import { getDateByTimeZone, formatDate } from '@/helpers/date'
import DateConverter from '@/models/DateConverter'
import { formatAsPercentage, formatNumber } from '@/helpers/number'
import {
  renderValue,
  renderValueByKey,
  renderValueJoinBySymbol,
  renderValueYesNo,
} from '@/helpers/render-value'

Vue.filter('getDateTimeZone', str => getDateByTimeZone(str))
Vue.filter('formatDate', formatDate)
Vue.filter('convertToLocalDate', DateConverter.convertDateToUserDateFormatted)
Vue.filter('formatAsPercentage', formatAsPercentage)
Vue.filter('formatNumber', formatNumber)
Vue.filter('renderValue', renderValue)
Vue.filter('renderValueByKey', renderValueByKey)
Vue.filter('renderValueYesNo', renderValueYesNo)
Vue.filter('renderValueJoinBySymbol', renderValueJoinBySymbol)
