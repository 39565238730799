import deliveryServiceRepository from '@/repository/admin/deliveryServiceRepository'
import { defaultFilters } from '@/models/DeliveryService/helpers'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import deliveryService1CRepository from '@/repository/admin/deliveryService1CRepository'

const state = {
  filters: StorageService.get(storageKeys.DELIVERY_SERVICES_FILTERS) || { ...defaultFilters },
  deliveryServicesList: [],
  tableOptions: TableOptions.defaultTableOptions(),
  loadingData: false,
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  deliveryServicesList: state => state.deliveryServicesList,
  loadingData: state => state.loadingData,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadDeliveryServicesList({ commit }, filters) {
    commit('setFilters', filters)
    StorageService.set(storageKeys.DELIVERY_SERVICES_FILTERS, filters)
    commit('setLoadingData', true)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await deliveryServiceRepository.list(preparedFilters)

    commit('setDeliveryServicesList', { data, filters })
    commit('setLoadingData', false)

    return { deliveryServicesList: data.items, pagination: data.pagination }
  },

  async searchDeliveryServicesList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await deliveryServiceRepository.list(preparedFilters)

    return { deliveryServicesList: data.items, pagination: data.pagination }
  },

  async loadAvailableServicesList(context, { cityId, offerId }) {
    const { data } = await deliveryServiceRepository.listAvailable({
      city_id: cityId,
      offer_id: offerId,
    })
    return { availableServiceList: data || [] }
  },

  async getDeliveryService(context, id) {
    const { data } = await deliveryServiceRepository.get(id)
    return data
  },

  async updateDeliveryServices({ dispatch, state }, payload) {
    const {
      id,
      active,
      name,
      type,
      comment,
      idOneC,
      dayForPayment,
      updateByService,
      deliveryServiceCode,
    } = payload
    const response = await deliveryServiceRepository.put(id, {
      active,
      name,
      type,
      comment,
      idOneC,
      dayForPayment,
      updateByService,
      deliveryServiceCode,
    })
    dispatch('loadDeliveryServicesList', state.filters)
    return response
  },

  async createDeliveryService({ dispatch }, payload) {
    const { name, active, type, comment, idOneC, dayForPayment } = payload
    const response = await deliveryServiceRepository.post({
      name,
      active,
      type,
      comment,
      idOneC,
      dayForPayment,
    })
    dispatch('loadDeliveryServicesList', { ...defaultFilters })
    return response
  },
  async loadDeliveryService1C(context, id) {
    const { data } = await deliveryService1CRepository.get(id)
    return data
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.DELIVERY_SERVICES_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.DELIVERY_SERVICES_FILTERS, updatedFilters)
  },
}

const mutations = {
  setDeliveryServicesList(state, { data, filters }) {
    state.deliveryServicesList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },

  setLoadingData(state, payload) {
    state.loadingData = payload
  },
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
