<template>
  <ui-date-range-picker
    v-model="innerDate"
    v-bind="datePickerSettings"
    @input="onInput"
    @reset="onReset"
  >
    <template #activator="props">
      <slot name="activator" v-bind="props" />
    </template>
  </ui-date-range-picker>
</template>
<script>
export default {
  name: 'ui-date-range-wrapper',
  props: {
    // awaiting format 'YYYY-MM-DD HH:mm:ss|YYYY-MM-DD HH:mm:ss'
    value: {
      type: String,
      default: '',
    },
    datePickerSettings: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      innerDate: {
        start: '',
        end: '',
      },
    }
  },
  watch: {
    value: {
      handler(dates) {
        if (dates) {
          const [start, end] = dates.split('|')
          this.innerDate.start = start
          this.innerDate.end = end
        }
      },
      immediate: true,
    },
  },
  methods: {
    onInput() {
      const dates = `${this.innerDate.start}|${this.innerDate.end}`

      this.$emit('input', dates)
    },
    onReset() {
      this.$emit('reset')
    }
  },
}
</script>
