export default [
    {
        path: '/events',
        name: 'pageEventsList',
        meta: { title: 'events.listTitle', roles: ['executor'] },
        component: () => import('@/pages/events'),
    },
    {
        path: '/events/create',
        name: 'pageEventsCreate',
        meta: { title: 'events.createFormTitle', roles: ['executor'] },
        component: () => import('@/pages/events/create'),
    },
    {
        path: '/events/:id/edit',
        name: 'pageEventsEdit',
        meta: { title: 'events.editFormTitle', roles: ['executor'] },
        component: () => import('@/pages/events/id/edit'),
    },
]
