import SuperRepository from '../superRepository'

export default new (class EventPointRepository extends SuperRepository {
  baseUri() {
    return 'event_point'
  }
  list = async (params, config = {}) => {
    return this.httpClient().get(`${this.baseUri()}`, params, config)
  }
  bulkCreate = async (id, users) =>
    this.httpClient().post(`${this.baseUri()}/${id}/bulk_create`, users)
})()