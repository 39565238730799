export const locale = {
  exampleCrud: {
    typeOneC: 'Тип 1С',
    typeCallCenter: 'Тип Калл Центр',
    activity: 'Активность ',
    name: 'Название',
    actions: 'Действия',
    title: 'Заголовок Круда',
    editExampleCrud: 'Редактирование сущности',
  },
  base: {
    mainPage: 'Главная',
    save: 'Сохранить',
    cancel: 'Отменить',
    remove: 'Удалить',
    delete: 'Убрать',
    create: 'Создать',
    areYouSureToDelete: 'Вы действительно хотите удалить?',
    areYouSureToDeactivate: 'Подтвердите деактивацию',
    areYouSureToActivate: 'Подтвердите активацию',
    yes: 'Да',
    no: 'Нет',
    add: 'Добавить',
    edit: 'Редактировать',
    editing: 'Редактирование',
    time: 'Время',
    back: 'Назад',
    filters: 'Фильтры',
    apply: 'Применить',
    selectAll: 'Выбрать все',
    clearAll: 'Очистить все',
    deactivate: 'Деактивировать',
    activate: 'Активировать',
    accepted: 'Принято',
    processing: 'В обработке',
    incorrect: 'Неправильный',
    approved: 'Одобренный',
    rejected: 'Отклоненный',
    search: 'Поиск',
    noData: 'Нет данных',
    all: 'Все',
    allCount: 'Все ({count})',
    onlyChosen: 'Только выбранные',
    attention: 'Внимание',
    fill: 'Заполнить',
    validationError: 'Ошибка валидации',
    validationErrorServer: 'Ошибка сервера',
    deliveryServiceAndWarehouseCitiesValidationError:
      'Заполните либо удалите набор полей в блоке "Служба доставки и склад"',
    noMoreItems: 'Весь список загружен',
    noElementsFound: 'Не найдено элементов',
    import: 'Импорт',
    resetFilters: 'Сбросить фильтры',
    createdAt: 'Дата создания',
    createdBy: 'Кто создал',
    actions: 'Действия',
    city: 'Город',
    district: 'Район',
    region: 'Область',
    country: 'Страна',
    today: 'Сегодня',
    yesterday: 'Вчера',
    currentMonth: 'Текущий месяц',
    noDataAvailable: 'Данные недоступны',
    startTypingText: 'Начните вводить текст',
    change: 'Изменить',
    day: 'Дней',
    hour: 'Часов',
    minute: 'Минут',
    toTheBegining: 'В начало',
    warning: 'Предупреждение',
    choosePeriod: 'Выбрать период',
    filtersActive: 'Активные',
    filtersDeactivated: 'Деактивированные',
    filtersAll: 'Все',
    selectFields: 'Выбрать колонки',
    open: 'Свернуть',
    close: 'Развернуть',
    close2: 'Закрыть',
    reset: 'Сбросить',
    operator: 'Оператор',
    notOperator: 'Не оператор',
    export: 'Экспорт',
    images: 'Изображение',
    product: 'Продукт',
    selected: 'Выбрано',
    items: 'элементов',
    onPage: 'На странице',
    paginationOnPage: 'На странице',
    paginationFrom: 'из',
    history: 'История',
    field: 'Поле',
    rub: 'Рубль',
    uah: 'Гривна',
    mdl: 'Молдавский лей',
    success: 'Успешно',
    comments: 'Комментарии',
    choose: 'Выбрать',
    value: 'Значение',
    role: 'Должность',
    group: 'Группа',
    month: 'Месяц',
    niche: 'Ниша',
    total: 'Всего',
    from: 'из',
    mobileView: 'Для корректной работы системы воспользуйтесь десктопной версией',
    goToMain: 'На главную',
    showNumber: 'Показать номер',
    failedToLoad: 'Не удалось загрузить',
    failedToLoadDescription: 'Ошибка во время загрузки страницы. Попробуйте перезагрузить страницу',
    limitText: 'и еще {count}',
    addSelectedOptions: 'Добавить выбранные опции',
    removeSelectedOptions: 'Исключить выбранные опции',
    ok: 'Хорошо',
    selectDate: 'Выбрать дату',
    presets: {
      today: 'Сегодня',
      todayAndYesterday: 'Сегодня и вчера',
      yesterday: 'Вчера',
      currentWeek: 'Текущая неделя',
      previousWeek: 'Прошлая неделя',
      currentMonth: 'Текущий месяц',
      previousMonth: 'Прошлый месяц',
      currentYear: 'Текущий год',
    },
    beWaiting: 'Ожидайте',
    dataLoading: 'Данные обрабатываются',
  },
  uploadArea: {
    maxSize: 'Максимальный размер файла {maxSize} MB',
    moveYourFileOr: 'Перетащите ваш файл или',
    upload: 'загрузите',
    limit: 'Максимальное количество файлов - {limit} которое можно загрузить',
    invalidType: 'Неверный формат файла для загрузки',
  },
  lang: {
    ru: 'Русский',
    en: 'Английский',
  },
  filters: {
    all: 'Все',
    activity: 'Активность',
    operator: 'Тип: Оператор',
    terminalPayment: 'Наличие терминала',
  },
  authLogin: {
    username: 'Email',
    password: 'Пароль',
    loginIn: 'Войти',
    invalidCredentials: 'Неправильный email или пароль!',
    forgetPassword: 'Забыли пароль?',
    welcome: 'Добро пожаловать!',
    authText: 'Некомерційна професійна організація об’єднує всіх приватних виконавців України.'
  },
  authRestore: {
    email: 'Email',
    forgetPassword: 'Забыли пароль?',
    authRestoreDesc: 'Введите email и получите ссылку для восстановления:',
    send: 'Отправить',
    checkYourEmail: 'Проверьте почту',
    sentDesc: 'Мы отправили ссылку для восстановления пароля на',
    isNotFounded: 'Email не соответствует ни одной учетной записи. Попробуйте еще раз!',
  },
  authRecoveryPassword: {
    createNewPassword: 'Создайте пароль',
    create: 'Создать',
    first: 'Новый пароль',
    second: 'Повторите',
    titleOfDemands: 'Ваш пароль должен состоять из:',
    lengthDemand: 'минимум 8 символов',
    capitalDemand: 'одну заглавную букву',
    lowercaseDemand: 'одну строчную буквы',
    numberDemand: 'одну цифру (0-9)',
    symbolDemand: 'один символ (!@#%&*.)',
    example: 'Пример',
    exampleSymbols: 'Vtlq3*fsdfhqwj*',
    length: '8',
    generatePassword: 'Сгенерировать пароль',
    sameField: 'Пароли должны совпадать',
  },
  error: {
    invalid: '{name} не является допустимым',
    required: '{name} обязательное поле',
    requiredIf: '{name} обязательное поле',
    email: 'Неверный формат Email',
    maxLength: 'Максимальная длинна символов {length}',
    minLength: 'Минимальная длинна символов {length}',
    maxValue: 'Максимальное значение: {value}',
    minValue: 'Минимальная значение: {value}',
    onlyNumbers: 'Вводить допустимо только цифры',
    onlyIntegerNumbers: 'Вводить допустимо только целые числа',
    onlyWords: 'Вводить допустимо только буквы',
    allow: 'Ваш пароль должен состоять из: ',
    sameField: 'Пароли должны совпадать',
    regexPassword: 'Неверный формат пароля',
    url: 'Неверный формат URL',
    dateFromLess: 'Дата "c" должна быть меньше чем дата "по"',
    dateToLess: 'Дата "по" должна быть больше чем дата "c"',
    commaSpaceRule: 'Начало и конец поля не должны содержать пробел или запятую',
    lengthDemand: 'минимум 8 символов',
    capitalDemand: 'одну заглавную букву',
    lowercaseDemand: 'одну строчную буквы',
    numberDemand: 'одну цифру (0-9)',
    symbolDemand: 'один символ (!@#%&*.)',
    latinDemand: 'Пароль содержит отлько латинские буквы',
    invalidValue: 'Неправильное значение',
    notBeEmpty: 'Значение не должно быть пустым',
    error403Title: 'Ой, произошла ошибка...',
    error403Desc: 'Страница не существует или у вас нет прав доступа к ней.',
    error404Title: 'Ой, произошла ошибка...',
    error404Desc: 'Страница не существует или у вас нет прав доступа к ней.',
    emailExist: 'Такая почта уже есть в системе',
  },
  dashboardNotifications: {
    notifications: 'Уведомления',
    clear: 'Очистить',
    unread: 'Непрочитанные',
    read: 'Прочитанные',
    listIsEmpty: 'Список уведомлений пуст',
    loading: 'Загрузка уведомлений',
  },
}
export default locale
