<template>
  <keep-alive>
    <ui-card v-if="isShow" class="py-6">
      <slot />
    </ui-card>
  </keep-alive>
</template>

<script>
export default {
  name: 'ui-filter-entity',
  props: {
    isShowFilter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isShow() {
      return this.isShowFilter
    },
  },
}
</script>

<style scoped></style>
