import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ApiService from '@/services/api.service'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/vue-i18n'
import VueApexCharts from 'vue-apexcharts'
import '@/helpers/vue-filters'
import '@/plugins/directives'
import '@/plugins/globalComponents'
import '@/plugins/vue-multiselect'
import '@/plugins/v-tootip'
import '@/plugins/moment'
import '@/plugins/autocomplete'
import '@/plugins/vue-mask'
import '@/plugins/notification.socket'
import VueLazyLoad from 'vue-lazyload'
import LightBox from 'vue-image-lightbox'

Vue.config.productionTip = false

Vue.use(VueApexCharts)
Vue.use(VueLazyLoad)
Vue.component('light-box', LightBox)
Vue.component('apex-chart', VueApexCharts)

Vue.http = Vue.prototype.$http = ApiService

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
