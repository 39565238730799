import { get } from 'lodash'
import i18n from '@/plugins/vue-i18n'
import { joinBySymbol } from '@/helpers/filters'

export function renderValueByKey(value, key) {
  return renderValue(get(value, key))
}
export function renderValue(value) {
  return value || '-'
}
export function renderValueYesNo(value) {
  return value ? i18n.tc('base.yes') : i18n.tc('base.no')
}
export function renderValueJoinBySymbol(arr, symbol = ', ') {
  return joinBySymbol(arr, symbol)
}
