import klnStatistic from './kln-statistic'
import order from './order'

const routers = [
  {
    path: '/call-centre',
    redirect: '/call-centre/dashboard',
    component: () => import('@/layouts/LayoutAdmin.vue'),
    children: [
      {
        path: '/call-centre/dashboard',
        name: 'callCentreManagerDashboard',
        meta: {
          title: 'page.pageCallCentreManagerDashboard',
          roles: [
            'CC_DASHBOARD_ORDER',
            'CC_DASHBOARD_APPROVE_PERCENT',
            'CC_DASHBOARD_AVERAGE_TIME_IN_STATUS',
            'CC_DASHBOARD_APPROVE_BY_TIME',
          ],
        },
        component: () => import('@/pages/call-centre/Dashboard'),
      },
      ...klnStatistic,
      ...order
    ],
  },
]

export default routers
