export const locale = {
  // __GENERATED_TRANSLATIONS__ 
  userReport: {
     listTitle: 'Reports',
     editFormTitle: 'Update event',
     createFormTitle: 'Create event ',
     formFieldsBlockTitle: 'Main',
     formCreateUpdateSubmitErrorMessage: 'Request error',
     menuItemName: 'Reports',
     table: {
       id: 'ID',
       name: 'Event name',
       point: 'Mark',
       actions: 'Actions',
     },
     field: {
       name: 'Event name*',
       type: 'Event type*',
       date: 'Date & time of event*',
       link: 'Event link',
       role: 'Role*',
       description: 'Event description',
     },
  },
   
  reports: {
     listTitle: 'Reports',
     editFormTitle: 'Update event',
     createFormTitle: 'Create event ',
     formFieldsBlockTitle: 'Main',
     formCreateUpdateSubmitErrorMessage: 'Request error',
     menuItemName: 'Reports',
     table: {
       id: 'ID',
       name: 'Executor',
       actions: 'Actions',
     },
     field: {
       name: 'Event name*',
       type: 'Event type*',
       date: 'Date & time of event*',
       link: 'Event link',
       role: 'Role*',
       description: 'Event description',
     },
  },
   
  systemEvents: {
     listTitle: 'System events',
     editFormTitle: 'Update event',
     createFormTitle: 'Create event ',
     formFieldsBlockTitle: 'Main',
     formCreateUpdateSubmitErrorMessage: 'Request error',
     menuItemName: 'System events',
     table: {
       id: 'ID',
       name: 'Event name',
       type: 'Event type',
       date: 'Date & time',
       speakers: 'Speakers',
       description: 'Description',
       actions: 'Actions',
     },
     field: {
       name: 'Event name*',
       type: 'Event type*',
       date: 'Date & time of event*',
       link: 'Event link',
       role: 'Role*',
       description: 'Event description',
     },
  },
   
  events: {
     listTitle: 'My events',
     editFormTitle: 'Update event',
     createFormTitle: 'Create event ',
     formFieldsBlockTitle: 'Main',
     formCreateUpdateSubmitErrorMessage: 'Request error',
     menuItemName: 'Users',
     table: {
       id: 'ID',
       name: 'Event name',
       type: 'Event type',
       date: 'Date & time',
       role: 'Role',
       description: 'Description',
       point: 'Mark',
       actions: 'Actions',
     },
     field: {
       name: 'Event name*',
       nameShow: 'Event name',
       type: 'Event type*',
       typeShow: 'Event type',
       date: 'Date & time of event*',
       dateShow: 'Date & time of event',
       link: 'Event link',
       role: 'Role*',
       roleShow: 'Role',
       description: 'Event description',
     },
  },
   
  users: {
     listTitle: 'Users',
     editFormTitle: 'Update user',
     createFormTitle: 'Create user',
     formFieldsBlockTitle: 'Main',
     formCreateUpdateSubmitErrorMessage: 'Request error',
     menuItemName: 'Users',
     table: {
       id: 'ID',
       name: 'Name',
       role: 'Role',
       email: 'Email',
       notifications: 'Notifications',
       actions: 'Actions',
     },
     field: {
       name: 'Name*',
       lastName: 'Last name*',
       role: 'Role',
       email: 'Email*',
       password: 'Password*',
     },
  },
  settings: {
    settingsTitle: 'Settings',
    settingsGeneral: 'Main',
    menuItemName: 'Settings',
    saveSuccess: 'Data successfully updated',
    field: {
      domainName: 'Domain name',
      postBackUrl: 'URL for postbacks'
    },
    btn: {
      save: 'Save',
    },
  },
  lang: {
    ru: 'Russian',
    ua: 'Ukrainian',
    en: 'English'
  },
  base: {
    mainPage: 'Main',
    settings: 'Settings',
    save: 'Save',
    cancel: 'Cancel',
    remove: 'Remove',
    delete: 'Delete',
    create: 'Create',
    confirm: 'Confirm',
    areYouSureToDelete: 'Are you sure want to delete?',
    areYouSureToDeactivate: 'Confirm deactivation',
    areYouSureToActivate: 'Confirm activation',
    yes: 'Yes',
    no: 'No',
    add: 'Add New',
    edit: 'Edit',
    editing: 'Editing',
    time: 'Time',
    back: 'Back',
    filters: 'Filters',
    apply: 'Apply',
    selectAll: 'Select all',
    clearAll: 'Clear all',
    deactivate: 'Deactivate',
    activate: 'Activate',
    listStatusActive: 'Active',
    listStatusInactive: 'Inactive',
    search: 'Search',
    noData: 'No data',
    all: 'All',
    allCount: 'All ({count})',
    onlyChosen: 'Only chosen',
    attention: 'Attention',
    fill: 'Fill',
    validationError: 'Validation error',
    validationErrorServer: 'validation server error',
    noMoreItems: 'No more items',
    noElementsFound: 'No elements found',
    import: 'Import',
    exportTemplate: 'Export template',
    resetFilters: 'Reset',
    createdAt: 'Created at',
    createdBy: 'Created by',
    actions: 'Actions',
    city: 'City',
    district: 'District',
    region: 'Region',
    country: 'Country',
    today: 'Today',
    yesterday: 'Yesterday',
    currentMonth: 'Current month',
    noDataAvailable: 'No data available',
    startTypingText: 'Start typing text',
    change: 'Change',
    month: 'Month',
    day: 'Day',
    hour: 'Hour',
    minute: 'Minute',
    toTheBegining: 'To the beginning',
    warning: 'Warning',
    choosePeriod: 'Choose period',
    selectPeriod: 'Select period',
    filtersActive: 'Active',
    filtersDeactivated: 'Deactivated',
    filtersAll: 'All',
    selectFields: 'Select fields',
    open: 'Hide',
    close: 'Show',
    close2: 'Close',
    reset: 'Reset',
    operator: 'Operator',
    notOperator: 'Not operator',
    export: 'Export',
    exportReport: 'Export report',
    images: 'Images',
    product: 'Product',
    selected: 'Selected',
    items: 'items',
    onPage: 'Showing',
    paginationOnPage: 'Showing',
    paginationFrom: 'from',
    history: 'History',
    field: 'Field',
    success: 'Success',
    comments: 'Comments',
    choose: 'Choose',
    value: 'Value',
    role: 'Role',
    group: 'Group',
    niche: 'Niche',
    total: 'Total',
    from: 'from',
    mobileView: 'For correct display use desktop version',
    goToMain: 'Go to main',
    showNumber: 'Show number',
    failedToLoad: 'Failed to load',
    failedToLoadDescription: 'Error while loading page. Try reload page',
    limitText: 'and {count} more',
    addSelectedOptions: 'Add selected options',
    removeSelectedOptions: 'Remove selected options',
    ok: 'Хорошо',
    selectDate: 'Select date',
    presets: {
      today: 'Today',
      todayAndYesterday: 'Today and yesterday',
      yesterday: 'Yesterday',
      currentWeek: 'Current week',
      previousWeek: 'Previous week',
      currentMonth: 'Current month',
      previousMonth: 'Previous month',
      currentYear: 'Current year',
    },
  },
  page: {
    login: 'Login',
    pageError403: 'Access forbidden',
    pageError404: 'Page not found',
    restore: 'Forgot password?',
    recoveryPassword: 'Recovery password',
  },
  authLogin: {
    username: 'Username',
    password: 'Password',
    loginIn: 'Login',
    invalidCredentials: 'Wrong email or password',
    forgetPassword: 'Forgot password?',
    welcome: 'Welcome!',
  },
  authRestore: {
    email: 'Email',
    forgetPassword: 'Forgot password?',
    authRestoreDesc: 'Enter your email and get a recovery link:',
    send: 'Send',
    checkYourEmail: 'Check your mail',
    sentDesc: 'We have sent a password reset link to',
    isNotFounded: 'Email not found. Try again!',
    wrongEmail: 'Wrong email?',
    enterAgain: 'Enter again!'
  },
  authRecoveryPassword: {
    createNewPassword: 'Create a password',
    create: 'Create',
    first: 'New Password',
    second: 'Repeat',
    titleOfDemands: 'Your Password must to consist of:',
    lengthDemand: 'minimum 8 characters',
    capitalDemand: 'one capital letter',
    lowercaseDemand: 'one lowercase letter',
    numberDemand: 'one digit (0-9)',
    symbolDemand: 'one character (!@#$%^&*.)',
    example: 'Example:',
    exampleSymbols: 'Vtlq3*fsdfhqwj*',
    length: '8',
    generatePassword: 'Generate password',
    sameField: 'Passwords must match',
  },
  error: {
    invalid: '{name} is invalid',
    required: '{name} required',
    requiredIf: '{name} required',
    email: 'Wrong Email format',
    maxLength: 'Max length - {length} symbols',
    minLength: 'Min length - {length} symbols',
    maxValue: 'Max value: {value}',
    minValue: 'Min value: {value}',
    onlyNumbers: 'Only numbers',
    onlyIntegerNumbers: 'Only integer numbers',
    onlyIntegerPositiveNumbers: 'Only integer positive numbers',
    onlyWords: 'Only words',
    allow: 'Your password should contain: ',
    sameField: 'Passwords should be the same',
    regexPassword: 'Wrong password format',
    url: 'Wrong URL',
    dateFromLess: 'Date "from" should be less than date "to"',
    dateToLess: 'Date "to" should be greater than date "from"',
    commaSpaceRule: 'Start and end of field shouldn\'t contain space or comma',
    lengthDemand: '8 symbols at least',
    capitalDemand: 'One capital letter',
    lowercaseDemand: 'One lowercase letter',
    numberDemand: 'One digit (0-9)',
    symbolDemand: 'One symbol (!@#%&*.)',
    latinDemand: 'Password contains only latin letters',
    invalidValue: 'Wrong value',
    notBeEmpty: 'Value shouldn\'t be empty',
    error403Title: 'Ooops, something went wrong...',
    error403Desc: 'Page doesn\'t exists or you don\'t have access.',
    error404Title: 'Ooops, something went wrong...',
    error404Desc: 'Page doesn\'t exists or you don\'t have access.',
    emailExist: 'This email is already registered',
  },
  dashboardNotifications: {
    notifications: 'Notifications',
    clear: 'Clear',
    unread: 'Unread',
    read: 'Read',
    listIsEmpty: 'List is empty',
    loading: 'Loading notifications',
  },
}

export default locale
