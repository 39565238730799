import blackPhone from './black-phone'
import language from './language'
import product from './product'
import productType from './product-type'

const routers = [
  ...blackPhone,
  ...language,
  ...product,
  ...productType,
  {
    path: '/settings',
    name: 'settingsDashboard',
    meta: { title: 'settings.settingsTitle' },
    component: () => import('@/pages/settings/Dashboard'),
  }
]
export default routers
