import Vue from 'vue'

const components = [
  require.context('@/components/ui/framework/form'),
  require.context('@/components/ui/framework/general'),
  require.context('@/components/ui/general'),
]
import UiDateSinglePicker from '@/components/ui/UIDatePickerField/UiDateSinglePicker.vue'
import UiDateRangePicker from '@/components/ui/UIDatePickerField/UiDateRangePicker.vue'
import UiDateRangeWrapper from '@/components/ui/UIDatePickerField/UiDateRangeWrapper.vue'
import UiDateMonthPicker from '@/components/ui/UIDatePickerField/UiDateMonthPicker.vue'

components.forEach(itemsImported => {
  itemsImported.keys().forEach(fileName => {
    const component = itemsImported(fileName).default
    Vue.component(component.name, component)
  })
})

Vue.component('ui-date-single-picker', UiDateSinglePicker)
Vue.component('ui-date-range-picker', UiDateRangePicker)
Vue.component('ui-date-range-wrapper', UiDateRangeWrapper)
Vue.component('ui-date-month-picker', UiDateMonthPicker)
