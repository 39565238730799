import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import reportsRepository from '@/repository/generatedRepository/reportsRepository'
import TableOptions from '@/models/TableOptions'
import { defaultFilters } from "@/models/Reports/helpers";
import {getPaginatedList, mockPagination, omitEmptyDeepFilters, prepareFilters} from '@/helpers/filters'
import i18n from "@/plugins/vue-i18n";
import moment from "moment/moment";
import { DATE_FORMAT } from "@/constans/date";
const { saveAs } = require('file-saver')

const state = {
  users: [],
  events: [],
  filters: StorageService.get(storageKeys.REPORTS_FILTERS) || { ...defaultFilters },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  users: state => state.users,
  usersPrepared: state =>
    getPaginatedList(state.users, state.tableOptions.pagination, {
      sortBy: state.filters.sortBy,
      sortDesc: state.filters.sortDesc,
    }),
  events: state => state.events,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadData(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.REPORTS_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...appliedFilters,
    })

    const { data } = await reportsRepository.getReport(preparedFilters)

    context.commit('setList', {
      data,
      pagination: mockPagination(data.users, filters),
      filters
    })
    return { products: data.items, pagination: data.pagination }
  },
  async loadExportFile(context, filters) {
    const {dateFrom, dateTo} = filters.filter
    const response = await reportsRepository.exportReport(
      { ...filters.filter, xlsx: true },
      { responseType: 'blob' },
    )
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.tc('users.report')}${dateFrom ?
        ` (${moment(dateFrom).format(DATE_FORMAT)}` : 
        ''}${dateTo ?
        ` ${moment(dateTo).format(DATE_FORMAT)})` :
        ''}.xlsx`
    saveAs(blob, filename)
    return response
  },
  async searchReports(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await reportsRepository.list(preparedFilters)

    return { products: data.items, pagination: data.pagination }
  },
  async loadItem(context, id) {
    const { data } = await reportsRepository.get(id)
    return data
  },
  createItem(context, payload) {
    return reportsRepository.post(payload)
  },
  updateItem(context, payload) {
    const { id, ...data } = payload
    return reportsRepository.put(id, data)
  },
  deleteItem(context, payload) {
    return reportsRepository.delete(payload)
  },
  async loadItemOneC(context, id) {
    const { data } = await reportsRepository.getOneC(id)
    return { product: data }
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.REPORTS_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.REPORTS_FILTERS, updatedFilters)
  },
  updatePaginationAdminReport(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.REPORTS_FILTERS, filters)
    context.commit('setPagination', filters.pagination)
  },
  updateSortAdminReport(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.REPORTS_FILTERS, filters)
    context.commit('setSort', {
      sortBy: filters.sortBy,
      sortDesc: filters.sortDesc,
    })
  }
}

const mutations = {
  setList(state, { data, pagination, filters }) {
    state.users = [...data.users]
    state.events = [...data.events]
    state.tableOptions = new TableOptions(pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
  setPagination(state, pagination) {
    state.tableOptions = {
      ...state.tableOptions,
      page: pagination.page,
      pagination,
      totalItems: Math.ceil(state.tableOptions.total / pagination.limit),
    }
  },
  setSort(state, { sortBy, sortDesc }) {
    state.tableOptions = {
      ...state.tableOptions,
      sortBy,
      sortDesc,
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
