import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/UserEvents/helpers'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import EventPointRepository from "@/repository/generatedRepository/eventPointRepository";

const state = {
  list: [],
  filters: StorageService.get(storageKeys.EVENT_USERS_FILTERS) || { ...defaultFilters },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  list: state => state.list,
}

const actions = {
  async loadEventUsers(context, payload) {
    const {id, filters, user} = payload
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.EVENT_USERS_FILTERS, filters)
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    let preparedFilters = prepareFilters({
      ...filters,
      filter: {
        ...appliedFilters,
        event: {
          id: id
        },
      },
      cfFilter: appliedCfFilters,
    })
    if (user) {
      preparedFilters = {
        ...preparedFilters,
        filter: {
          ...preparedFilters.filter,
          user: {
            id: user
          }
        }
      }
    }
    const { data } = await EventPointRepository.list(preparedFilters)
    context.commit('setList', { data, filters })
    return { products: data.items, pagination: data.pagination }
  },
  async createEventUser(context, payload){
    const { id, ...data } = payload
    return EventPointRepository.bulkCreate(id, data)
  },
  async updateEventUser(context, payload){
    const { id, ...data } = payload
    return EventPointRepository.put(id, data)
  },
  async removeEventUser(context, payload){
    const { id } = payload
    return EventPointRepository.delete(id)
  },
  filtersStorageReset(context) {
    StorageService.remove(storageKeys.EVENT_USERS_FILTERS)
    context.commit('setFilters', {...defaultFilters})
  },
}

const mutations = {
  setList(state, { data, filters }) {
    state.list = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}