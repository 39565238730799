const ID_TOKEN_KEY = 'id_token';

export const getTokenData = () => window.localStorage.getItem(ID_TOKEN_KEY)
export const getAccessToken = () => {
  const tokenData = window.localStorage.getItem(ID_TOKEN_KEY)
  if (tokenData) {
    const { token } = JSON.parse(tokenData)
    return token
  }
  return ''
};
export const saveTokenData = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token)
};

export const destroyTokenData = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY)
};

export const getTokenBearer = config => {
  const tokenData = getTokenData()
  if (config.url === 'token/refresh') {
    return ''
  }
  else if (tokenData) {
    const { token } = JSON.parse(tokenData)
    return `Bearer ${token}`
  }
  return ''
}

export default { getTokenData, saveTokenData, destroyTokenData, getAccessToken, getTokenBearer }
