import efficiencyAdditionalProjects from '@/router/manager/additional-projects'

const routers = [
  {
    path: '/manager',
    redirect: '/manager/dashboard',
    component: () => import('@/layouts/LayoutAdmin.vue'),
    children: [
      {
        path: '/manager/dashboard',
        name: 'managerDashboard',
        meta: {
          title: 'page.pageManagerDashboard',
          roles: [
            'MNG_DASHBOARD_ORDER',
            'MNG_DASHBOARD_HANDING_RANSOM',
            'MNG_DASHBOARD_DELIVERY_SERVICE_SHIPMENT',
            'MNG_DASHBOARD_APPROVE_PERCENT',
            'MNG_DASHBOARD_DELIVERY_SERVICE_DEBT',
            'MNG_DASHBOARD_AVERAGE_TIME_IN_STATUS',
            'MNG_DASHBOARD_APPROVE_BY_TIME',
          ],
        },
        component: () => import('@/pages/call-centre/Dashboard'),
      },
      ...efficiencyAdditionalProjects,
    ],
  },
]
export default routers
