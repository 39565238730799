export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    userGroups: null,
    operators: null,
    offerGroups: null,
    offers: null,
    offerNiches: null,
    salesTypes: null,
    offerSalesModels: null,
    regions: null,
    type: null,
    deliveryServices: null,
    countries: null,
    dateFrom: '2022-01-01',
    dateTo: '2023-03-31',
  },
  sortBy: ['sort'],
  sortDesc: [false],
  clientOptions: {
    filterPanelExpanded: false,
  },
}
