import SuperRepository from '../superRepository'

export default new (class deliveryServiceRepo extends SuperRepository {
  baseUri() {
    return 'delivery_service'
  }

  listAvailable = async params =>
    this.httpClient().get(`${this.baseUri()}/available_services`, params)
})()
