<template>
  <v-checkbox
      v-model="getValue"
      :error-messages="getErrorMsg"
      :error="error"
      dense
      :ripple="false"
      hide-details="auto"
      :value="inputValue"
      v-bind="$attrs"
      v-on="$listeners"
      class="ui-checkbox"
  >
    <template v-for="(_, name) in $slots" v-slot:[name]>
      <slot :name="name"/>
    </template>
  </v-checkbox>
</template>

<script>
import {getErrorMsg} from '@/helpers/validation'

export default {
  name: "ui-checkbox",
  props: {
    value: null,
    inputValue: undefined,
    errorMessages: {
      type: [String, Array, Object],
    }
  },
  computed: {
    getValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    error() {
      if (!this.value) return this.$attrs.error
      return this.value.$error
    },
    getErrorMsg() {
      if (Array.isArray(this.errorMessages) || typeof this.errorMessages === 'string') {
        return this.errorMessages
      }
      return getErrorMsg(this.errorMessages)
    }
  },
}
</script>

<style lang="scss">
.ui-checkbox {
  .v-input--selection-controls__input {
    height: 16px;
    width: 16px;
  }

  .v-icon.mdi-checkbox-blank-outline.theme--light {
    color: var(--v-black-lighten3);
  }

  .theme--light.v-label {
    color: var(--v-black-darken3);
  }
}
</style>
