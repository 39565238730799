<template>
  <v-expansion-panels
    v-model="panel"
    accordion
    active-class="v-expansion-panel--active"
    class="ui-expansion-panels"
    v-bind="$attrs"
    v-on="$listeners"
    @input="$emit('input', $event)"
  >
    <v-expansion-panel v-for="(item, index) in items" :key="item.id || index">
      <v-expansion-panel-header>
        <slot name="header" :item="item" :is-active="isActive(index)">
          {{ item.name }}
        </slot>
        <template v-slot:actions>
          <v-icon :color="colorExpandIcon(index)" :size="sizeExpandIcon">mdi-chevron-down</v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <slot name="content" :item="item">
          {{ item.text }}
        </slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'UiExpansionPanels',
  props: {
    value: null,
    items: {
      type: Array,
      default: () => [],
    },
    sizeExpandIcon: {
      type: String,
      default: '20',
    },
  },
  computed: {
    panel: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    isActive() {
      return index => {
        return this.checkActive(index)
      }
    },
    colorExpandIcon() {
      return index => {
        return this.checkActive(index) ? 'primary base' : 'black darken-6'
      }
    },
  },
  methods: {
    checkActive(index) {
      return this.panel === index || (Array.isArray(this.panel) && this.panel.includes(index))
    },
  },
}
</script>

<style lang="scss">
.ui-expansion-panels {
  .v-expansion-panel {
    &:before {
      box-shadow: none;
    }
    &:after {
      opacity: 0;
    }
  }
  .v-expansion-panel-header {
    &--active {
      background-color: var(--v-primary-lighten6) !important;
      color: var(--v-primary-base) !important;
    }
  }
}
</style>
