import dashboardManagerRepository from '@/repository/admin/dashboardManagerRepository'

const actions = {
  async loadDeliveryService(context, date) {
    const { data } = await dashboardManagerRepository.deliveryStatistic(date)
    return data
  },
  async loadOrderAndApprove() {
    const { data } = await dashboardManagerRepository.orderAndApprove()
    return data
  },
  async loadDeliveryServiceDebt() {
    const { data } = await dashboardManagerRepository.deliveryServiceDebt()
    return data
  },
  async loadHangingRansom(context, date) {
    const { data } = await dashboardManagerRepository.hangingRansom(date)
    return data
  },
  async loadApprovePensioner(context, date) {
    const { data } = await dashboardManagerRepository.approvePensioner(date)
    return data
  },
  async loadAverageOrderStatusTime(context, payload) {
    const { data } = await dashboardManagerRepository.averageOrderStatusTime(payload)
    return data
  },
  async loadApprovesByTime(context, date) {
    const { data } = await dashboardManagerRepository.approvesByTime(date)
    return data
  },
}

export default {
  namespaced: true,
  actions,
}
