import { cloneDeep } from 'lodash'
import { joinBySymbol } from '@/helpers/filters'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  UIFilter: {
    country: {
      id: {
        value: null,
        key: 'in',
      },
    },
    offerGroups: {
      id: {
        value: null,
        key: 'in',
      },
    },
  },
  search: '',
}

export function preparedOfferStatisticsFilters(filter) {
  const filters = cloneDeep(defaultFilters.UIFilter)

  filters.country.id.value = joinBySymbol(filter.countries)
  filters.offerGroups.id.value = joinBySymbol(filter.offerGroups)

  return filters
}
