import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import i18n from '@/plugins/vue-i18n'
import callCentreRouters from './call-centre'
import settingsRouters from './settings'
import managerRouters from './manager'
import generatedRoutes from "./generated-routes";
import auth from './auth'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    ...auth,
    {
      path: '/',
      redirect: '/system-events',
      component: () => import('@/layouts/LayoutDefault.vue'),
      children: [
        {
          path: '/system-events',
          name: 'pageSystemEventsList',
          meta: { title: 'systemEvents.listTitle' },
          component: () => import('@/pages/system-events'),
        },
        ...generatedRoutes,
        ...callCentreRouters,
        ...settingsRouters,
        ...managerRouters,
        {
          path: '/403',
          name: 'pageError403',
          meta: { title: 'page.pageError403', noRedirect: true },
          component: () => import('@/pages/error/Error-403.vue'),
        },
        {
          path: '/404',
          name: 'pageError404',
          meta: { title: 'page.pageError404', noRedirect: true },
          component: () => import('@/pages/error/Error-404.vue'),
        },
      ],
    },

    // 404 page must be placed at the end !!!
    { path: '*', redirect: '/404', hidden: true },
  ],
})

const whiteList = ['loginPage', 'restorePage', 'recoveryPassword'] // no redirect whitelist

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  store.dispatch('AuthModule/onVerifyAuth').then(() => {
    const isAuthenticated = store.getters['AuthModule/isAuthenticated']
    if (whiteList.includes(to.name)) {
      next()
      return
    }

    if (!isAuthenticated) {
      next({ name: 'loginPage', query: to.meta?.noRedirect ? {} : { path: to.path, ...to.query } })
      return
    }

    if (store.getters['ProfileModule/profileHasFullAccess']) {
      next()
      return
    }

    const profilePermissions = store.getters['ProfileModule/profilePermissions']
    const hasAccessPage =
        !Array.isArray(to.meta.roles) || to.meta.roles.some(role => profilePermissions.has(role))

    if (!hasAccessPage) {
      next({ name: 'pageError403' })
      return
    }

    next()
  })
  // set page tab name
  document.title = i18n.t(`${to.meta.title}`)
})

export default router
