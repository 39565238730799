export default [
    {
        path: '/system-events/create',
        name: 'pageSystemEventsCreate',
        meta: { title: 'systemEvents.createFormTitle' },
        component: () => import('@/pages/system-events/create'),
    },
    {
        path: '/system-events/:id/edit',
        name: 'pageSystemEventsEdit',
        meta: { title: 'systemEvents.editFormTitle' },
        component: () => import('@/pages/system-events/id/edit'),
    },
]
