import SuperRepository from '../superRepository'

export default new (class userNotificationsRepository extends SuperRepository {
  baseUri() {
    return 'user_notification'
  }
  bulkRead = async (params = {}) => this.httpClient().post(`${this.baseUri()}/bulk_read`, params)
  getUnreadNotificationsCount = async (params = {}) =>
    this.httpClient().get(`${this.baseUri()}/unread_count`, params)
  removeAll = async (params = {}) =>
    this.httpClient().delete(`${this.baseUri()}/remove_all`, params)
})()
