import SuperRepository from '../superRepository'

export default new (class OfferRepository extends SuperRepository {
  baseUri() {
    return 'offer'
  }

  getOneC = async (id, params = {}) => this.httpClient().get(`offer_one_c/${id}`, params)

  statistic = async params => this.httpClient().get(`${this.baseUri()}/statistics`, params)

  coldStatistic = async params =>
    this.httpClient().post(`${this.baseUri()}/cold_statistics`, params)
})()
