/*
    Danger!
    You should not remove any comments from this file, if comments will be removed generator will be broken
*/

// __generator_imports__
import userReportRoute from './user-report'
import reportsRoute from './reports'
import systemEventsRoute from './system-events'
import eventsRoute from './events'
import usersRoute from './users'

const routes = [
// __imported_entities__
...userReportRoute,
...reportsRoute,
...systemEventsRoute,
...eventsRoute,
...usersRoute,

]

export default routes;
