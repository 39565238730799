<template>
  <div class="upload-area">
    <div
      class="upload-area--content px-6"
      :style="{ height }"
      :class="{ 'active': isActive && !disabled }"
      @dragenter.prevent.stop="onDragEnter"
      @dragover.prevent.stop="onDragOver"
      @dragleave.prevent.stop="onDragLeave"
      @drop.prevent.stop="onDrop"
    >
      <v-icon color="info" size="45">mdi-file-upload-outline</v-icon>
      <div class="d-flex flex-column align-center text-center" v-if="!video && !multiple">
        <div class="subtitle-2 black--text text--darken-3 my-3">
          {{ $t('uploadArea.moveYourFileOr') }}
          <span class="primary--text cursor-pointer" @click="onUploadFileByClick">
          {{ $t('uploadArea.upload') }}
        </span>
        </div>
        <div class="body-1 black--text text--lighten-2">
          {{ $t('uploadArea.maxLimit', {limit: limit, maxSize: maxSize}) }}
        </div>
      </div>
      <div class="d-flex flex-column align-center text-center" v-if="video">
        <div class="subtitle-2 black--text text--darken-3 my-3">
          {{ $t('uploadArea.moveYourVideoOr') }}
          <span class="primary--text cursor-pointer" @click="onUploadFileByClick">
            {{ $t('uploadArea.upload') }}
          </span>
        </div>
        <div class="body-1 black--text text--lighten-2">
          {{ $t('uploadArea.maxVideoLimit', {limit: limit, maxSize: maxSize}) }}
        </div>
      </div>
      <div class="d-flex flex-column align-center text-center" v-if="multiple">
        <div class="subtitle-2 black--text text--darken-3 my-3">
          {{ $t('uploadArea.moveYourMultipleOr') }}
          <span class="primary--text cursor-pointer" @click="onUploadFileByClick">
            {{ $t('uploadArea.upload') }}
          </span>
        </div>
        <div class="body-1 black--text text--lighten-2">
          {{ $t('uploadArea.maxMultipleLimit', {limit: limit, maxSize: maxSize, videoLimit: videoLimit, videoMaxSize: videoMaxSize}) }}
        </div>
      </div>
      <div
        v-if="$scopedSlots.actions"
        class="body-1 primary--text cursor-pointer mt-3">
        <slot name="actions"/>
      </div>
    </div>
    <slot name="list"></slot>
    <input
      ref="file"
      class="d-none"
      type="file"
      :multiple="limit > 1"
      @change="onChange($event.target.files)"
    >
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {messageErrorRequest} from "@/helpers/validation";
import {generateUUID} from "@/helpers/functions";
import arrayToStringCropper from "@/mixins/arrayToStringCropper";
import MediaFormatsMixin from "@/mixins/MediaFormatsMixin.vue";

export default {
  name: 'UploadArea',
  mixins: [arrayToStringCropper, MediaFormatsMixin],
  props: {
    height: {
      type: String,
      default: '225px',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxSize: {
      type: Number,
      default: 60
    },
    videoMaxSize: {
      type: Number,
      default: 400
    },
    limit: {
      type: Number,
      default: 1
    },
    videoLimit: {
      type: Number,
      default: 1
    },
    customAction: {
      type: Function,
      default: null,
    },
    autoProceed: {
      type: Boolean,
      default: true,
    },
    video: {
      type: Boolean,
      default: false,
    },
    loadedFiles: {
      type: Array,
      default: () => []
    },
    id: {
      type: [String, Number],
      default: '',
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false,
      files: [],
      preview: [],
      accept: [],
    }
  },
  computed: {
    currentFileFormat() {
      if (this.video) return this.videoFormat
      if (!this.video && this.multiple) return this.availableFormats
      return this.supportedFormat
    },
    currentLimit() {
      if (this.video) return this.videoLimit
      if (!this.video && this.multiple) return this.limit + this.videoLimit
      return this.limit
    }
  },
  methods: {
    ...mapActions('FileModule', ['loadFileWithConfig']),
    ...mapActions('NotificationModule', ['show']),
    onDragEnter() {
      this.isActive = true
    },
    onDragOver() {
      this.isActive = true
    },
    onDragLeave() {
      this.isActive = false
    },
    onDrop(e) {
      this.isActive = false
      if (!this.disabled) this.onChange(e.dataTransfer.files)
    },
    onUploadFileByClick() {
      if (!this.disabled) this.$refs.file.click()
    },
    onChange(files) {
      if (!files[0]) return
      const arrFiles = Array.from(files)
      if (this.checkErrors(arrFiles)) {
        return
      }
      this.loading = true

      if (this.autoProceed) {
        this.onLoadFiles(arrFiles)
      } else {
        const newFiles = arrFiles.map((file) => {
          const id = generateUUID()
          return {name: file.name, id, file}
        })
        this.files.push(...newFiles)
        newFiles.forEach(item => {
          this.preview.push({link: URL.createObjectURL(item.file), name: item.name, id: item.id})
        })
        this.$emit('updateFile', {preview: this.preview, files: this.files})
        this.preview = []
        this.files = []
      }
      this.$refs?.file?.value ? this.$refs.file.value = "" : null
    },
    onLoadFiles(files) {
      this.files = files.map((file) => {
        const id = generateUUID()
        return {name: file.name, progress: 0, id, file}
      })
      const promises = this.files.map(({file, id}) => {
        const formData = new FormData()
        formData.append('file', file)
        return this.fetchData(formData, file, id)
      })
      this.onUploadFile(promises)
    },
    fetchData(formData, file, id) {
      const onUploadProgress = (e) => this.onProgress(e, file, id)

      if (this.customAction) {
        return this.customAction(formData, onUploadProgress)
      }

      return this.loadFileWithConfig({
        formData,
        config: {
          onUploadProgress
        }
      })
    },
    async onUploadFile(promises) {
      try {
        const responses = await Promise.all(promises)
        this.$emit('upload', responses)
      } catch (e) {
        this.files = []
        const message = messageErrorRequest(e)
        this.show({type: 'error', message})
      } finally {
        this.$refs.file.value = null
      }
    },
    onProgress(e, f, id) {
      const file = this.files.find(i => i.id === id)

      const percent = Math.floor(e.loaded * 100 / f.size)
      file.progress = percent > 100 ? 100 : percent
    },
    defineFormat(item) {
      const isLoaded = !item.name ? item.originName : item.name
      const fileFormat = isLoaded.split('.').pop()
      if(this.imageFormat.includes(fileFormat)) return 'image'
      if(this.videoFormat.includes(fileFormat)) return 'video'
      if(this.fileFormat.includes(fileFormat)) return 'file'
    },
    onDelete(file) {
      this.$refs.file.value = null
      this.files = this.files.filter(item => item.id !== file.id)
      this.$emit('update:delete-file', file)
    },

    checkErrors(files) {
      if (this.isLimitError(files)) {
        this.showErrorNotification(this.$t('uploadArea.limit', {limit: this.currentLimit}))
        return true
      }
      return files.some(file => this.checkError(file))
    },
    checkError(file) {
      const maxSize = file.type.includes('video') ? this.videoMaxSize : this.maxSize
      if ((file.size / (1024 * 1024)).toFixed(2) > maxSize) {
        this.showErrorNotification(this.$t('uploadArea.maxSize', {maxSize: maxSize}))
        return true
      } else if (!this.currentFileFormat.includes(file.name.split('.').pop())) {
        this.showErrorNotification(this.$t('uploadArea.invalidType'))
        return true
      }
    },
    isLimitError(files) {
      const videoSum = [...files, ...this.loadedFiles, ...this.files]
        .filter(el => this.videoFormat
          .includes((el.name || el.originName).split('.').pop()))
        .length
      const filesSum = files.length + this.loadedFiles.length + this.files.length - videoSum
      if (this.multiple) return videoSum > this.videoLimit || filesSum > this.limit
      return (this.video ? videoSum : filesSum) > this.currentLimit
    },
    showErrorNotification(message) {
      this.show({type: 'error', message})
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-area {
  &--content {
    background: var(--v-black-lighten7);
    border: 1px dashed var(--v-black-lighten2);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all .2s ease-out;

    &.active {
      border: 1px solid var(--v-black-darken3);
    }
  }

  &--item-avatar {
    background: var(--v-black-lighten7);
    border-radius: 8px;
  }

  &--item {
    min-height: 40px;

    &-name {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: var(--v-black-darken3);
    }

    &-progress {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: var(--v-black-lighten6);
    }
  }
}

.items-list {
  img {
    border-radius: 4px;
  }
}

.preview {
  &-file {
    background: #F8F8F8;
    border-radius: 4px;
    width: 124px;
    height: 124px;
    display: flex;
    justify-content: center;
  }
  &-image {
    width: 124px;
    height: 124px;
    object-fit: cover;
    border-radius: 4px;
  }
  &-title {
    width: 124px;
  }
}
</style>
