import { cloneDeep } from 'lodash'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    city: {
      id: {
        value: '',
        operator: 'in',
      },
      district: {
        id: {
          value: '',
          operator: 'in',
        },
        region: {
          id: {
            value: '',
            operator: 'in',
          },
          country: {
            id: {
              value: '',
              operator: 'in',
            },
          },
        },
      },
    },
    deliveryService: {
      id: {
        value: '',
        operator: 'in',
      },
      type: {
        value: '',
        operator: 'in',
      },
    },
    active: 1,
  },
  sortBy: ['active', 'id'],
  sortDesc: [true, true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export function preparedDeliveryServiceFilters(deliveryServiceFilters, { deliveryService, type }) {
  const preparedDelivery = cloneDeep(deliveryServiceFilters)
  preparedDelivery.id = deliveryService?.id
  preparedDelivery.type = type

  return preparedDelivery
}
