import moment from "moment";
import {DATE_FORM_FORMAT} from "@/constans/date";
export const defaultFilters = {
    pagination: {
        limit: 20,
        page: 1,
    },
    filter: {
        dateFrom: moment().startOf('month').subtract(1, 'month').format(DATE_FORM_FORMAT),
        dateTo: moment().format(DATE_FORM_FORMAT)
    },
    sortBy: ['id'],
    sortDesc: [true],
    clientOptions: {
        filterPanelExpanded: false,
    },
}
