/*
    Danger!
    You should not remove any comments from this file, if comments will be removed generator will be broken
*/

import Vue from 'vue'
import Vuex from 'vuex'

import AuthModule from '@/store/modules/Auth.module'
import BlackPhoneModule from '@/store/modules/BlackPhone.module'
import DashboardManagerModule from '@/store/modules/DashboardManager.module'
import FileModule from '@/store/modules/File.module'
import LanguageModule from '@/store/modules/Language.module'
import ModalModule from '@/store/modules/Modal.module'
import NotificationModule from '@/store/modules/Notification.module'
import KlnSectionModule from '@/store/modules/KlnSection.module'
import OrderModule from '@/store/modules/Order.module'
import ProductModule from '@/store/modules/Product.module'
import ProductTypeModule from '@/store/modules/ProductType.module'
import ProfileModule from '@/store/modules/Profile.module'
import SettingModule from '@/store/modules/Setting.module'
import UserNotificationsModule from '@/store/modules/UserNotifications.module'
import WorkTimeModule from '@/store/modules/WorkTime.module'
import SalesScriptModule from '@/store/modules/SalesScript.module'
import OfferModule from '@/store/modules/Offer.module'
import DeliveryServiceModule from '@/store/modules/DeliveryService.module'
import DeliveryPointsModule from '@/store/modules/DeliveyPoints.module'
import FinanceSettingsModule from '@/store/modules/FinanceSettings.module'

// Generated modules imported under this 2 comments. DO NOT REMOVE ANY COMMENTS
// __generator_imports__
import ReportsModule from '@/store/modules/Reports.module'
import SystemEventsModule from '@/store/modules/SystemEvents.module'
import EventsModule from '@/store/modules/Events.module'
import UsersModule from '@/store/modules/Users.module'
import UserEventsModule from "@/store/modules/UserEvents.module";
import EventUsersModule from "@/store/modules/EventUsers.module";
import UserReportModule from "@/store/modules/UserReport.module";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    FinanceSettingsModule,
    DeliveryPointsModule,
    DeliveryServiceModule,
    OfferModule,
    AuthModule,
    BlackPhoneModule,
    DashboardManagerModule,
    FileModule,
    LanguageModule,
    ModalModule,
    NotificationModule,
    KlnSectionModule,
    OrderModule,
    ProductModule,
    ProductTypeModule,
    ProfileModule,
    SettingModule,
    UserNotificationsModule,
    WorkTimeModule,
    SalesScriptModule,

    // Generated modules under this comment. DO NOT REMOVE ANY COMMENTS
    // __imported_entities__
    ReportsModule,
    SystemEventsModule,
    EventsModule,
    UsersModule,
    UserEventsModule,
    EventUsersModule,
    UserReportModule,
  },
})
