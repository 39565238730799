export default [
  {
    path: '/settings/product-type',
    name: 'pageProductTypeList',
    meta: { title: 'page.pageProductTypeList', roles: ['SETTING_PRODUCT_TYPE_VIEW'] },
    component: () => import('@/pages/settings/product-type'),
  },
  {
    path: '/settings/product-type/create',
    name: 'pageProductTypeCreate',
    meta: { title: 'page.pageProductTypeCreate', roles: ['SETTING_PRODUCT_TYPE_VIEW'] },
    component: () => import('@/pages/settings/product-type/create'),
  },
  {
    path: '/settings/product-type/:id/edit',
    name: 'pageProductTypeEdit',
    meta: { title: 'page.pageProductTypeEdit', roles: ['SETTING_PRODUCT_TYPE_VIEW'] },
    component: () => import('@/pages/settings/product-type/id/edit'),
  },
]
